import React, { useEffect, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import 'objectFitPolyfill';

import { selectLocale } from 'models/localization';
import Routes from 'Routes';
import BannerSmallImg from 'assets/main_lobby/banner-container-small.png';
import BannerLargeImg from 'assets/main_lobby/banner-container-large.png';
import BannerView from '../../BannerTemplateSwitch';
import PageEntry from './PageEntry';
import LobbyFooterSwitch from '../../LobbyFooterSwitch';


const RootContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const BackgroundVideo = styled.video`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  margin-top: -100px;
  z-index: -1;
`;

const StyledBackgroundImage = styled.img`
  width: 100%;
  margin-top: -100px;
  z-index: -1;
`;

const ContentContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  bottom: 0;
`;

const BannerContainer = styled.div<{ left: number; top: number; width: number; height: number; background: string }>`
  position: absolute;
  left: ${({ left }) => left}%;
  top: ${({ top }) => top}%;
  width: ${({ width }) => width}%;
  height: ${({ height }) => height}%;
  flex: 1 0 auto;
  transform: translateX(-50%);
  background: ${({ background }) => background};
  background-size: contain;
  background-repeat: no-repeat;
`;

const Banner = styled(BannerView)`
  position: absolute;
  left: ${({ left }) => left}%;
  top: ${({ top }) => top}%;
  width: ${({ width }) => width}%;
  height: ${({ height }) => height}%;
  flex: 1 0 auto;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.9);
`;

const PageEntryTextContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const PageEntryText = styled.div`
  font-size: 1.9vw;
`;

const SmallPageEntryText = styled.div<{ locale: string }>`
  font-size: ${({ locale }) => (locale === 'ja' ? '1vw' : '2vw')};
`;

const StyledArrowForwardIosIcon = styled(ArrowForwardIosIcon)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const FooterWrapper = styled.div`
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: 0;
`;

const DesktopLobbyViewReedTemplate = () => {
  const locale = useSelector(selectLocale);
  const { t } = useTranslation(['lobby', 'common']);
  const backgroundVideoSrc = 'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/92cbc295-f28f-426e-b95d-f22d8075dbfb.mp4';
  const backgroundPreviewSrc = 'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/57077598-b146-4dca-888e-347894fbc6f1.jpg';

  const autoPlay = { left: true, middle: true, right: true };
  const banners: any = (t('banners', '', { returnObjects: true }) || {});

  useEffect(() => {
    window.objectFitPolyfill();
  }, []);

  return (
    <RootContainer>
      <ContentContainer>
        <StyledBackgroundImage src={backgroundPreviewSrc} />
        <BackgroundVideo autoPlay loop muted>
          <source src={backgroundVideoSrc} type="video/mp4" />
        </BackgroundVideo>
        {banners?.left?.length > 0 && (
          <BannerContainer
            left={20}
            top={0}
            height={25}
            width={20}
            background={`url(${BannerSmallImg})`}
          >
            <Banner
              left={50}
              top={33}
              height={65}
              width={100}
              assets={banners.left}
              autoPlay={autoPlay.left}
            />
          </BannerContainer>
        )}
        {banners?.right?.length > 0 && (
          <BannerContainer
            left={80}
            top={0}
            height={25}
            width={20}
            background={`url(${BannerSmallImg})`}
          >
            <Banner
              left={50}
              top={33}
              height={65}
              width={100}
              assets={banners.right}
              autoPlay={autoPlay.right}
            />
          </BannerContainer>
        )}
        {banners?.middle?.length > 0 && (
          <BannerContainer
            left={50}
            top={0}
            height={30}
            width={30}
            background={`url(${BannerLargeImg})`}
          >
            <Banner
              left={50}
              top={16}
              height={76}
              width={100}
              assets={banners?.middle}
              autoPlay={autoPlay.middle}
            />
          </BannerContainer>
        )}
        <PageEntry
          left={30}
          top={40}
          height={15}
          width={25}
          bgColor="#0E7ABC"
          link={Routes.hall}
        >
          <PageEntryText>
            <Trans
              defaults="To Exhibition Hall"
              i18nKey="lobby:entry.to_exhibition_hall"
            />
          </PageEntryText>
          <StyledArrowForwardIosIcon />
        </PageEntry>
        <PageEntry
          left={70}
          top={40}
          height={15}
          width={25}
          bgColor="#0CA58B"
          link={Routes.custom}
        >
          <PageEntryTextContainer>
            <Trans
              defaults="How to utilize virtual expo?"
              i18nKey="lobby:entry.how_to_use_oms"
              components={{
                b: <PageEntryText />,
                i: <SmallPageEntryText locale={locale} />,
              }}
            />
          </PageEntryTextContainer>
          <StyledArrowForwardIosIcon />
        </PageEntry>
        <FooterWrapper>
          <Suspense fallback={null}>
            <LobbyFooterSwitch />
          </Suspense>
        </FooterWrapper>
      </ContentContainer>
    </RootContainer>
  );
};

export default DesktopLobbyViewReedTemplate;
