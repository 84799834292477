import React, { useMemo } from 'react';
import {
  PAGE_NAMES, EVENT_NAME, APP, REED_APPS,
} from 'appenv';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Footer from 'components/footer/Footer';
import ProductList from './components/ProductList';

const BACKGROUND_IMAGE_URL = 'https://contents-virtual.eventxtra.com/uploads/virtualdemo_staging/originals/90ceb449-28e8-458a-a3af-3f82a8545afe.png';

const PageContainer = styled.div`
  width: 100%;
  min-height: 100%;
  background: url(${BACKGROUND_IMAGE_URL});
  background-attachment: fixed;
  position: relative;
  padding-bottom: 90px;
`;

const RootContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  overflow: auto;
`;

const FooterWrapper = styled.div`
  padding-top: 20px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

const ProductPage = () => {
  const { t } = useTranslation();
  const pageTitle = PAGE_NAMES.products || t('page.products', 'Products');
  const eventName = useMemo(() => {
    if (REED_APPS.includes(APP as any)) return t('common:title', EVENT_NAME);
    return EVENT_NAME;
  }, [t]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${pageTitle} | ${eventName}`}</title>
        <meta name="description" content={`${pageTitle} page of ${eventName}.`} />
      </Helmet>
      <PageContainer>
        <RootContainer>
          <ProductList />
        </RootContainer>
        <FooterWrapper>
          <Footer hashState="/products" />
        </FooterWrapper>
      </PageContainer>
    </>
  );
};

export default ProductPage;
