/* eslint-disable camelcase */
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import { APP, EVENT_NAME, ENABLED_FEATURES } from 'appenv';
import { selectBoothById } from 'models/xtra_cms/booths';
import CircularLoadingIndicator from 'components/indicators/CircularLoadingIndicator';
import { selectExhibitorById } from 'models/exhibitors';
import Footer from 'components/footer/Footer';
import { LightboxContextStateProvider } from 'components/booth/CustomLightbox/LightboxContext';
import { BoothProfileTabsContextStateProvider } from './components/BoothProfileTabsContext';
import BoothProfileContainer from './BoothProfileContainer';
import useAutoJoinMsg from './useAutoJoinMsg';
import useBoothEnterTracking from './useBoothEnterTracking';

const RootContainer = styled.div<{ bgColor?: string }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: ${({ bgColor }) => (bgColor || '#FFFFFF')};
`;

const Container = styled.div<{ wrapperBgColor?: string }>`
  width: auto;
  display: block;
  overflow: auto;

  .booth-template-wrapper {
    width: 100%;
    background: ${({ wrapperBgColor }) => wrapperBgColor || 'initial'};
  }
`;

const FooterWrapper = styled.div`
  padding-top: 20px;
`;

const PolyU618Container = styled(Container)`
  @media (max-width: 960px) {
    margin-top: 56px;
  }
`;

const EmptyState = styled.div`
  padding: 50px;
  text-align: center;
`;

const BoothNotFoundContainer = () => (
  <EmptyState>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{`Booth Not Found | ${EVENT_NAME}`}</title>
      <meta name="description" content={`Booth not found in ${EVENT_NAME} event.`} />
    </Helmet>
    Booth not found
  </EmptyState>
);

const selectFetching = ENABLED_FEATURES.xtraCMS
  ? (state: any) => state.cms.booths.fetching
  : (state: any) => state.exhibitors.fetching;

const selectById: (state: any, id: any) => any = ENABLED_FEATURES.xtraCMS
  ? selectBoothById
  : selectExhibitorById;

const BoothPageContainer = () => {
  const { boothId } = useParams();
  const fetching = useSelector(selectFetching);
  const booth = useSelector((state) => selectById(state, boothId));

  useAutoJoinMsg(booth?.id);
  useBoothEnterTracking(booth);

  const boothBgColor = booth?.booth_bg_color;
  const bgColor = useMemo(() => {
    if (APP === 'polyu618') {
      return '#E0E0E0';
    }
    return boothBgColor;
  }, [boothBgColor]);

  const hasFunding = !!booth?.tags?.find((tag) => tag.includes('Stage of Funding: '));
  const wrapperBgColor = useMemo(() => {
    switch (APP) {
      case 'hkstp608':
        return (hasFunding
          ? 'linear-gradient(180deg, #ACE0F9 -9.96%, #FFF1EB 65.29%, rgba(255, 255, 255, 0) 104.7%)'
          : 'linear-gradient(180deg, #E6D8CC 0%, #FCFBF9 41.63%, #DFE9F3 82.69%, rgba(255, 255, 255, 0) 100%)');
      default:
        return 'initial';
    }
  }, [hasFunding]);

  const BoothContainer = useMemo(() => {
    switch (APP) {
      case 'polyu618':
        return PolyU618Container;
      case 'hkstp608':
      default:
        return Container;
    }
  }, []);

  return (
    <RootContainer id="booth-profile-root" bgColor={bgColor}>
      <BoothContainer id="booth-container" wrapperBgColor={wrapperBgColor}>
        <BoothProfileTabsContextStateProvider>
          {fetching && <CircularLoadingIndicator />}
          {!fetching && (
            booth
              ? (
                <LightboxContextStateProvider>
                  <BoothProfileContainer
                    booth={booth}
                  />
                </LightboxContextStateProvider>
              )
              : <BoothNotFoundContainer />
          )}
        </BoothProfileTabsContextStateProvider>
        {!fetching && (
          <FooterWrapper>
            <Footer hashState={`/booth/${boothId}`} />
          </FooterWrapper>
        )}
      </BoothContainer>
    </RootContainer>
  );
};

export default BoothPageContainer;
