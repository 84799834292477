import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import ReactImageMagnify from 'react-image-magnify';
import './slider.css';
import ReactSlick from 'react-slick';
import { ProductImage } from 'models/products';
import { useTranslation } from 'react-i18next';

const RootContainer = styled.div<{ hasDots: boolean }>`
  & .slick-dots {
    display: flex !important;
    justify-content: center;
    align-items: center;
    bottom: ${({ hasDots }) => (hasDots ? '-65px' : 0)};
  }

  & .slick-list {
    border: 1px solid #DBDEE2;
    box-sizing: content-box;
  }

  & .slick-dots li {
    width: 60px;
    height: 60px;
  }

  @media (min-width: 960px) {
    max-width: 400px;
  }
`;

const SliderThumbnail = styled.a<{ selected: boolean }>`
  width: 60px;
  height: 60px;
  max-width: 60px;
  max-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #DBDEE2;
  box-sizing: border-box;
  padding: 4px;
  box-shadow: ${({ selected }) => (selected ? '0px 0px 8px 2px rgba(219, 222, 226,1)' : 'initial')};

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

declare global {
  interface Window {
    objectFitPolyfill: () => unknown;
  }
}

const ImageContainer = styled.div`
  height: 100%;
  max-height: 400px;

  & > div {
    height: 400px !important;
  }

  & > div > img {
    height: 400px !important;
    object-fit: contain;
  }
`;

const NoImageText = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: bold;
  font-size: 18px;
  padding: 16px;
`;

const SliderImage = ({
  originalImageUrl,
  previewImageUrl,
  alt,
}: ProductImage & { rimProps: Record<string, any> }) => {
  const { t } = useTranslation();
  const [imageWidth, setImageWidth]: [
    number,
    React.Dispatch<React.SetStateAction<number>>
  ] = useState(undefined);
  const [imageHeight, setImageHeight]: [
    number,
    React.Dispatch<React.SetStateAction<number>>
  ] = useState(undefined);
  const [error, setError] = useState(false);

  useEffect(() => {
    const hiddenImage = new Image();
    hiddenImage.onload = () => {
      setImageWidth(hiddenImage.width);
      setImageHeight(hiddenImage.height);
    };
    hiddenImage.src = originalImageUrl;
  }, [originalImageUrl]);

  const handleImageError = useCallback(() => {
    setError(true);
  }, []);

  return (
    <ImageContainer key={originalImageUrl}>
      {!error ? (
        <ReactImageMagnify
          smallImage={{
            alt,
            isFluidWidth: true,
            src: previewImageUrl,
            onError: handleImageError,
          }}
          imageStyle={{
            objectFit: 'contain',
            objectPosition: 'center',
          }}
          isHintEnabled
          shouldHideHintAfterFirstActivation={false}
          enlargedImagePosition="over"
          style={{
            height: 400,
          }}
          largeImage={{
            src: originalImageUrl,
            width: imageWidth,
            height: imageHeight,
          }}
          lensStyle={{ backgroundColor: 'rgba(0,0,0,.6)' }}
          imageClassName="rim-image"
        />
      ) : (
        <NoImageText>{t('no_image', 'No Image')}</NoImageText>
      )}
    </ImageContainer>
  );
};

const ProductImageSlider = ({
  rimProps,
  rsProps,
  images = [],
}: {
  rimProps?: any;
  rsProps?: any;
  images: ProductImage[];
}) => {
  const [currentSlide, setCurrentSlide]: [number, React.Dispatch<React.SetStateAction<number>>] = useState(0);
  useEffect(() => {
    const slides: HTMLCollectionOf<Element> = document.getElementsByClassName('rim-image');
    const numItems: number = slides.length;
    for (let i = 0; i < numItems; i += 1) {
      const imageItem: HTMLImageElement = slides.item(i) as HTMLImageElement;
      imageItem.setAttribute('data-object-fit', 'contain');
    }
  }, []);

  useEffect(() => {
    window.objectFitPolyfill();
  });

  return (
    <RootContainer hasDots={images.length > 1}>
      <ReactSlick
        arrows={false}
        infinite
        afterChange={setCurrentSlide}
        speed={500}
        slidesToScroll={1}
        slidesToShow={1}
        dots={images.length > 1}
        customPaging={(index: number) => (
          <SliderThumbnail selected={currentSlide === index}>
            <img src={images[index].previewImageUrl} alt={images[index].alt} className="rim-image" />
          </SliderThumbnail>
        )}
        {...rsProps}
      >
        {images.map((image) => <SliderImage {...image} rimProps={rimProps} />)}
      </ReactSlick>
    </RootContainer>
  );
};

export default ProductImageSlider;
