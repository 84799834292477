import React from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import Slider from 'react-slick';
import playButton from 'assets/play-button.png';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const RootContainer = styled.div`
  user-select: none;
`;

const SliderContainer = styled.div`
  color: #484848;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;

  & video {
    background-color: black;
  }
`;

const StyledSlider = styled(Slider)`
  width: 100%;

  .slick-prev, .slick-next {
    display: none !important;
    z-index: 1000;

    &::before {
      color: #484848;
    }
  }

  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }

  .slick-slide {
    height: 100%;
  }

  &:hover {
    .slick-prev, .slick-next {
      display: block !important;
    }
  }
`;

const StyledAnchor = styled.a`
  flex: 0 0 auto;
  margin: 0;
  text-decoration: none;
  user-select: all;
  pointer-events: all;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: 0;
  }
`;

const StyledImg = styled.img`
  flex: 0 0 auto;
  max-width: 100%;
  min-height: 100%;
  margin: auto;
  height: fill-available;
  outline: none;
`;

const StyledIframe = styled.iframe`
  height: 100%;
  width: 100%;
  user-select: all;
  pointer-events: all;
`;

export interface BannerViewProps {
  className?: string;
  assets?: any[];
  autoPlay?: boolean;
}

const BannerView: React.FC<BannerViewProps> = ({
  className = '', assets = [], autoPlay = false,
}: BannerViewProps) => {
  const [videoStateMap, setVideoStateMap] = React.useState({});

  return (
    <RootContainer className={className}>
      <SliderContainer>
        <StyledSlider
          arrows={assets.length > 1}
          autoplay={autoPlay}
          swipe={false}
          beforeChange={(oldIndex) => {
            setVideoStateMap({
              ...videoStateMap,
              [oldIndex]: false,
            });
          }}
        >
          {
            assets.map(({
              src,
              type,
              href,
            }, index) => {
              switch (type) {
                case 'embed':
                  return (
                    <StyledIframe key={src} src={src} allow="fullscreen" />
                  );
                case 'video':
                  return (
                    <ReactPlayer
                      key={src}
                      url={src}
                      controls
                      playIcon={<img src={playButton} alt="Play Button" />}
                      width="100%"
                      playing={videoStateMap[index]}
                      onPlay={() => setVideoStateMap({
                        ...videoStateMap,
                        [index]: true,
                      })}
                      onPause={() => setVideoStateMap({
                        ...videoStateMap,
                        [index]: false,
                      })}
                      height="280px"
                      config={{
                        file: {
                          attributes: {
                            autoPlay: true,
                            disableRemotePlayback: true,
                            disablePictureInPicture: true,
                            controlsList: 'nodownload',
                            muted: true,
                          },
                        },
                      }}
                      playsinline
                      style={{ zIndex: 100, backgroundColor: 'black' }}
                    />
                  );
                case 'image':
                default:
                  return (
                    href && href.trim() !== '' ? (
                      <StyledAnchor key={src} href={href} target="_blank" rel="noopener noreferrer">
                        <StyledImg src={src} />
                      </StyledAnchor>
                    ) : (
                      <StyledImg key={src} src={src} />
                    )
                  );
              }
            })
          }
        </StyledSlider>
      </SliderContainer>
    </RootContainer>
  );
};

export default BannerView;
