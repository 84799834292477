/* eslint-disable camelcase */
import {
  createSlice,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { fetchCMS, updateFromCMS } from './common/thunks';

export interface Hall {
  id: string;
  name: {
    [locale: string]: string;
  };
  booths: string[];
}

const hallAdapter = createEntityAdapter<Hall>();

export const {
  selectEntities: selectHallEntities,
  selectAll: selectAllHalls,
  selectById: selectHallById,
} = hallAdapter.getSelectors((state: any) => state.cms.halls);

export const hallsSlice = createSlice({
  name: 'halls',
  initialState: hallAdapter.getInitialState({ fetching: true }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCMS.pending, (state: any) => {
      state.fetching = true;
    });
    builder.addCase<any>(fetchCMS.fulfilled, (state, action) => {
      hallAdapter.upsertMany(state, action.payload?.halls || {});
      state.fetching = false;
    });
    builder.addCase(fetchCMS.rejected, (state: any) => {
      state.fetching = false;
    });
    builder.addCase<any>(updateFromCMS.fulfilled, (state, action) => {
      hallAdapter.upsertMany(state, action.payload?.halls || {});
    });
  },
});

export default hallsSlice.reducer;
