import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Slider from 'react-slick';
import ReactPlayer from 'react-player';
import playButton from 'assets/play-button.png';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { APP, REED_APPS } from 'appenv';
import Apps from 'apps';
import { envSwitch } from 'utils/envUtils';

const RootContainer = styled.div`
  padding: 16px 16px 0;
`;

const StyledSlider = styled(Slider)`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;

  .slick-track {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slick-slide {
    height: 100%;
    background-color: #FFF;
  }

  & img {
    width: ${({ width }) => (width)};
  }
`;

const AspectRatioContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding-bottom: 49.56%;
`;

const AssetContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const CustomSlide = ({ children }) => (
  <AspectRatioContainer>
    <AssetContainer>
      {children}
    </AssetContainer>
  </AspectRatioContainer>
);

CustomSlide.propTypes = {
  children: PropTypes.node.isRequired,
};

const StyledAnchor = styled.a`
  margin: 0;
  text-decoration: none;
  user-select: all;
  pointer-events: all;
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledImgBackgroundSize = envSwitch([
  [['ms624', ...REED_APPS], 'contain'],
], 'auto 100%');

const StyledImg = styled.div`
  flex: 0 0 auto;
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  height: 100%;
  background-image: ${({ img }) => `url("${img}")`};
  background-repeat: no-repeat;
  background-size: ${({ bannerFit }) => bannerFit || StyledImgBackgroundSize};
  background-position: center;
`;

const StyledIframe = styled.iframe`
  height: 100%;
  width: 100%;
  user-select: all;
  pointer-events: all;
`;

const SliderBulletList = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 0;
`;

const SliderBulletContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
`;

const SliderBullet = styled.div`
  width: 8px;
  height: 2px;
  background: #484848;
  opacity: ${({ active }) => (active ? '1' : '0.2')};
  border-radius: 4px;
`;

const VideoPreviewContainer = styled.div`
  display: contents;

  & .react-player__preview {
    background-size: contain !important;
  }
`;

const hideThumbnail = [Apps.Lighthouse710].includes(APP);

const MobileBannerView = ({ assets }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [videoStateMap, setVideoStateMap] = useState({});
  const sliderRef = useRef();

  const sortedAssets = assets.sort((a, b) => a.sort - b.sort);

  const onSlideChange = (oldIndex, newIndex) => {
    setVideoStateMap({
      ...videoStateMap,
      [oldIndex]: false,
      [newIndex]: true,
    });
    setActiveIndex(newIndex);
  };

  const onVideoPlaying = (index) => {
    sliderRef.current?.slickPause();
    setVideoStateMap({
      ...videoStateMap,
      [index]: true,
    });
  };

  const onVideoStopped = (index) => {
    sliderRef.current?.slickPlay();
    setVideoStateMap({
      ...videoStateMap,
      [index]: false,
    });
  };

  return (
    <RootContainer>
      <StyledSlider
        autoplay
        ref={sliderRef}
        arrows={false}
        dots={false}
        beforeChange={onSlideChange}
        pauseOnHover={false}
        width={APP === 'paloalto623' ? '100%' : 'auto'}
      >
        {
          sortedAssets.map(({
            src,
            type,
            href,
            thumbnail,
            banner_fit: bannerFit,
          }, index) => {
            switch (type) {
              case 'embed':
                return (
                  <CustomSlide key={index}>
                    <StyledIframe src={src} allow="fullscreen" />
                  </CustomSlide>
                );
              case 'video': {
                const thumbnailLink = thumbnail?.data?.thumbnails?.find((it) => it?.url?.endsWith('?key=directus-large-contain'))?.url;
                return (
                  <CustomSlide key={index}>
                    <VideoPreviewContainer>
                      <ReactPlayer
                        light={hideThumbnail ? false : thumbnailLink || true}
                        url={src}
                        controls
                        playIcon={<img src={playButton} alt="Play Button" />}
                        width="100%"
                        playing={videoStateMap[index]}
                        onPlay={() => onVideoPlaying(index)}
                        onPause={() => onVideoStopped(index)}
                        onEnded={() => onVideoStopped(index)}
                        height="100%"
                        config={{
                          file: {
                            attributes: {
                              autoPlay: true,
                              disableRemotePlayback: true,
                              disablePictureInPicture: true,
                              controlsList: 'nodownload',
                              muted: true,
                            },
                          },
                        }}
                        playsinline
                        style={{ zIndex: 100, backgroundColor: [Apps.Lighthouse710].includes(APP) ? 'unset' : 'black' }}
                      />
                    </VideoPreviewContainer>
                  </CustomSlide>
                );
              }
              case 'image':
              default:
                return (
                  <CustomSlide key={index}>
                    {
                      href && href.trim() !== '' ? (
                        <StyledAnchor href={href} target="_blank" rel="noopener noreferrer">
                          <StyledImg img={src} bannerFit={bannerFit} />
                        </StyledAnchor>
                      ) : (
                        <StyledImg img={src} bannerFit={bannerFit} />
                      )
                    }
                  </CustomSlide>
                );
            }
          })
        }
      </StyledSlider>
      <SliderBulletList>
        {
          assets.map((_, index) => (
            <SliderBulletContainer key={index}>
              <SliderBullet active={index === activeIndex} />
            </SliderBulletContainer>
          ))
        }
      </SliderBulletList>
    </RootContainer>
  );
};

MobileBannerView.propTypes = {
  assets: PropTypes.arrayOf(PropTypes.shape({
    src: PropTypes.string,
    type: PropTypes.string,
  })),
};

MobileBannerView.defaultProps = {
  assets: [],
};

export default MobileBannerView;
