import React, { useState, useMemo, useEffect } from 'react';
import { BRAND_COLOR } from 'appenv';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Collapse } from 'react-collapse';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import FilterCheckboxOption from './FilterCheckboxOption';
import FilterOption from './FilterOption';

const RootContainer = styled.div`
  width: 100%;
  padding: 12px 0;
`;

const FilterTitleContainer = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ hasEnabledSingleLevelFilter }) => (hasEnabledSingleLevelFilter ? 'flex-start' : 'space-between')};
  transition: all 0.3s;
  color:
    ${({ isSelected, hasEnabledSingleLevelFilter }) => {
    const selectedColor = BRAND_COLOR || '#0CA58B';
    const notSelectedColor = hasEnabledSingleLevelFilter ? '#484848' : '#A2AAB3';
    return isSelected ? selectedColor : notSelectedColor;
  }};
`;

const FilterTitleText = styled.span`
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  padding: 12px 0;
  display: inline-block;
  text-transform: none;
`;

const StyledCheckbox = withStyles(() => ({
  colorPrimary: {
    color: BRAND_COLOR || '#0CA58B',
  },
}))(Checkbox);

const FilterCategoryContainer = ({
  label,
  openOnMount,
  customOrder,
  selectedFilter,
  filterOptions,
  filter,
  onClick,
  subLevelOnClick,
  filterCustomLabel,
  allowSingleLevelFilter,
  setSelectedFilter,
}) => {
  const [open, setOpen] = useState(openOnMount);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    setIsInitialized(true);
  }, []);

  const filterOrdering = useMemo(() => {
    if (customOrder?.[filter]) {
      return customOrder[filter];
    }
    if (filterOptions?.[filter]) {
      return filterOptions[filter];
    }
    return [];
  }, [customOrder, filter, filterOptions]);

  const isSingleLevel = !filterOptions?.[filter] || (
    Array.isArray(filterOptions[filter])
    && filterOptions[filter].length === 1
    && filterOptions[filter][0] === filter
  );
  const hasEnabledSingleLevelFilter = isSingleLevel && allowSingleLevelFilter;

  const onFilterTitleContainerClick = () => {
    if (hasEnabledSingleLevelFilter) {
      const cloned = { ...selectedFilter };
      if (cloned?.[filter]?.length > 0) {
        delete cloned[filter];
      } else {
        cloned[filter] = [0];
      }
      setSelectedFilter(cloned);
      return;
    }
    setOpen((prev) => !prev);
  };

  if (!isInitialized || !filterOptions?.[filter]) return null;

  const isSelected = hasEnabledSingleLevelFilter ? selectedFilter?.[filter]?.length > 0 : true;

  return (
    <RootContainer>
      <FilterTitleContainer hasEnabledSingleLevelFilter={hasEnabledSingleLevelFilter} isSelected={isSelected} onClick={onFilterTitleContainerClick}>
        {
          hasEnabledSingleLevelFilter && (
            <StyledCheckbox
              checked={isSelected}
              color="primary"
            />
          )
        }
        <FilterTitleText>
          {filterCustomLabel[label] ? filterCustomLabel[label] : label}
        </FilterTitleText>
        {
          !hasEnabledSingleLevelFilter && (
            open
              ? <RemoveIcon style={{ paddingLeft: '4px' }} />
              : <AddIcon style={{ paddingLeft: '4px' }} />
          )
        }
      </FilterTitleContainer>
      {!hasEnabledSingleLevelFilter && (
        <Collapse isOpened={open}>
          {Array.isArray(filterOptions[filter])
            ? filterOrdering.map((option, index) => (
              filterOptions?.count?.[option] ? (
                <FilterCheckboxOption
                  index={index}
                  key={option}
                  label={option}
                  onClick={onClick}
                  selectedOption={selectedFilter?.[filter]}
                  category={filter}
                  sumOfCategory={filterOptions?.count?.[option]}
                />
              ) : null
            )) : (
              filterOrdering.map((eachkey) => (
                <FilterOption
                  key={eachkey}
                  category={eachkey}
                  label={eachkey}
                  options={filterOptions?.[filter]?.[eachkey]}
                  onClick={subLevelOnClick}
                  selectedFilter={selectedFilter?.[filter]?.[eachkey]}
                  filterOptions={filterOptions}
                  topCategory={label}
                />
              ))
            )}
        </Collapse>
      )}
    </RootContainer>
  );
};

FilterCategoryContainer.propTypes = {
  label: PropTypes.string.isRequired,
  openOnMount: PropTypes.bool.isRequired,
  customOrder: PropTypes.shape(),
  selectedFilter: PropTypes.shape().isRequired,
  filterOptions: PropTypes.shape().isRequired,
  filter: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  subLevelOnClick: PropTypes.func.isRequired,
  filterCustomLabel: PropTypes.shape().isRequired,
  allowSingleLevelFilter: PropTypes.bool,
  setSelectedFilter: PropTypes.func.isRequired,
};

FilterCategoryContainer.defaultProps = {
  customOrder: {},
  allowSingleLevelFilter: false,
};

export default FilterCategoryContainer;
