import React from 'react';
import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { LocalizedProduct } from 'models/products';
import ProductItem from 'components/product/ProductItem';

const RecommendationsContainer = styled.div`
  padding: 8px 24px 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const StyledPaper = styled(Paper)`
  margin: 8px 16px;
  align-items: stretch;
`;

const ProductRecommendations = ({ boothProducts }: {
  boothProducts: LocalizedProduct[];
}) => (
  <RecommendationsContainer>
    {Object.values(boothProducts).map((product) => (
      <StyledPaper elevation={4}>
        <ProductItem product={product} />
      </StyledPaper>
    ))}
  </RecommendationsContainer>
);

export default ProductRecommendations;
