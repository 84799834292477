import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectLocale } from 'models/localization';
import { ENABLED_FEATURES } from 'appenv';

const useBoothEnterTracking = (booth: any) => {
  const locale = useSelector(selectLocale);
  const boothId = booth?.id;
  const boothName = ENABLED_FEATURES.xtraCMS ? booth?.name[locale] : booth?.name;

  useEffect(() => {
    if (!boothId) return;
    window.trackingEvent('Booth', 'Enter', `${boothId} - ${boothName}`);
  }, [boothId, boothName]);
};

export default useBoothEnterTracking;
