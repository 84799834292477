import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import Truncate from 'react-truncate';

import ChatContext from 'components/chat/ChatContext';
import UserAvatar from 'components/UserAvatar';
import ChatroomIcon from 'components/icons/Chatroom';

const RootContainer = styled.div`
  height: 64px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
`;

const UserInfoContainer = styled.div`
  flex: 1 1 auto;
  margin-left: 10px;
  width: 60%;
`;

const UserInfo = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.408px;
  color: #484848;
  max-width: 100%;
  overflow: hidden;
`;

const UserTag = styled.span`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  letter-spacing: -0.408px;
  color: #FFF;
  border-radius: 2px;
  background-color: rgb(255, 66, 66);
  opacity: 0.4;
  padding: 0 5px;
  margin-left: 8px;
`;

const UserDescription = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.408px;
  color: #484848;
  margin-top: 8px;
`;

const StyledChatroomIcon = styled(ChatroomIcon)`
  cursor: pointer;
`;

const ParticipantListItem = ({
  uuid,
  name,
  userTag,
  userDescription,
}) => {
  const { showChatWindow } = useContext(ChatContext);

  const onPrivateChatBtnClick = () => {
    showChatWindow(uuid, 'private', name);
    window.trackingEvent('1on1Chatroom_Enter', 'Participant_List_Click', uuid);
  };

  return (
    <RootContainer>
      <UserAvatar name={name} />
      <UserInfoContainer>
        <UserInfo>
          {name}
          {
            userTag && (
              <UserTag>{userTag}</UserTag>
            )
          }
        </UserInfo>
        {
          userDescription.length > 0 && (
            <UserDescription>
              <Truncate lines={2} ellipsis="...">
                {userDescription}
              </Truncate>
            </UserDescription>
          )
        }
      </UserInfoContainer>
      <IconButton onClick={onPrivateChatBtnClick}>
        <StyledChatroomIcon
          color="#484848"
          width="24px"
          height="24px"
        />
      </IconButton>
    </RootContainer>
  );
};

ParticipantListItem.propTypes = {
  uuid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  userTag: PropTypes.string,
  userDescription: PropTypes.string,
};

ParticipantListItem.defaultProps = {
  userTag: '',
  userDescription: '',
};

export default ParticipantListItem;
