/* eslint-disable camelcase */
import React, { useContext, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import {
  EVENT_NAME, APP, PAGE_NAMES, ENABLED_FEATURES,
} from 'appenv';
import Routes from 'Routes';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowFowardIcon from '@material-ui/icons/ArrowForwardIosRounded';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';
import { selectExhibitorIds } from 'models/exhibitors';
import { selectBoothIds } from 'models/xtra_cms/booths';
import { selectLocale } from 'models/localization';
import useURLSearchParams from 'hooks/useURLSearchParams';
import { useTranslation } from 'react-i18next';

import XtraBreadcrumbs from 'components/XtraBreadcrumbs';
import CustomLightbox from 'components/booth/CustomLightbox';
import { LightboxContext } from 'components/booth/CustomLightbox/LightboxContext';
import MobileProfileSwitch from './MobileProfileSwitch';
import BoothTemplateSwitch from './BoothTemplateSwitch';
import BoothProfileTabsContainer from './BoothProfileTabsContainerTemplateSwitch';
import { BoothProfileTabsContext } from './components/BoothProfileTabsContext';

const INDUSTRY_CATEGORY = [
  'Consumer & Retail',
  'Education',
  'Logistics & Manufacturing',
  'Travel & Hospitality',
  'Finance & Insurance',
  'Healthcare',
  'Real Estate & Construction',
  'Smart City & Government',
];

const arrowStyles = {
  top: 350,
  zIndex: 1000,
  position: 'fixed',
  backgroundColor: '#FFFFFF',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
  },
};

const useStyles = makeStyles(() => ({
  prevArrow: {
    left: 20,
    ...arrowStyles,
  },
  nextArrow: {
    right: 20,
    ...arrowStyles,
  },
}));

const scrollDown = () => {
  document.getElementById('booth-container').scroll({ top: 1000, left: 0, behavior: 'smooth' });
};

const filterIndustryVerticals = (tags) => {
  const filteredIndustryTags = tags.filter((tag) => (
    tag.includes('Industry: ')
  )).map((tag) => (
    tag.replace('Industry: ', '')
  ));
  const result = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const tag of filteredIndustryTags) {
    // eslint-disable-next-line no-restricted-syntax
    for (const cat of INDUSTRY_CATEGORY) {
      if (tag.includes(cat)) {
        const content = tag.replace(`${cat}: `, '');
        result[cat] = result[cat] ? [...result[cat], content] : [content];
      }
    }
  }
  return result;
};

const mapDefaultTargetBooth = (booth, locale) => {
  const translation = booth.translations[locale] || {};
  const isFundingKind = booth.tags.some((tag) => tag.includes('Stage of Funding: '));
  const coreTechnology = booth.tags.find((tag) => (tag.includes('Core Technology: ')) || '')?.replace('Core Technology: ', '');
  const fundingStage = (booth.tags.find((tag) => tag.includes('Stage of Funding: ')) || '')?.replace('Stage of Funding: ', '');
  const hashtags = booth.tags.filter((tag) => tag.includes('#')).join(' ') || '';
  const industryVerticals = filterIndustryVerticals(booth.tags);

  const verticalBanner = translation.vertical_banner_detail;
  const descriptionBanner = translation?.description_detail;
  const logoBanner = translation.logo_detail;

  const logoThumbs = logoBanner?.preview?.data?.full_url ?? booth.logo?.data?.full_url;

  const bannerFile = verticalBanner?.preview ?? verticalBanner?.file;
  const bannerThumbs = bannerFile?.data?.thumbnails ?? translation.vertical_banner?.data?.thumbnails;
  const bannerFullImgUrl = verticalBanner?.preview?.data?.full_url ?? verticalBanner?.image?.data?.full_url;
  const bannerFileUrl = verticalBanner?.file?.data?.full_url;
  const bannerFileType = verticalBanner?.file?.type;
  const bestBannerThumb = bannerThumbs?.find((thumb) => thumb?.url?.endsWith('?key=thumbnail'));

  const targetBooth = {
    id: booth.id,
    logo: logoThumbs,
    verticalBanner: bestBannerThumb?.url,
    verticalBannerFullImg: bannerFullImgUrl,
    verticalBannerFile: bannerFileUrl,
    verticalBannerFileType: bannerFileType,
    verticalBannerUrl: translation.vertical_banner_url,
    name: translation.name,
    description: descriptionBanner?.description,
    boothNumber: booth.booth_number,
    meetupUrl: booth.meetup_url,
    industry: booth.industry,
    country: booth.country,
    type: booth.type,
    design: booth.booth_design,
    website: booth.website || '',
    boothBgColor: booth.booth_bg_color,
    boothColor: booth.booth_color,
    boothColorRight: booth?.booth_color_right,
    attachments: booth?.attachments || booth?.files || [],
    contacts: booth.contacts || [],
    socialMedias: booth.contacts?.filter((contact) => contact.type !== 'email' && contact.type !== 'phone') || [],
    emailContacts: booth.contacts?.filter((contact) => contact.type === 'email') || [],
    phoneContacts: booth.contacts?.filter((contact) => contact.type === 'phone') || [],
    extraFields: translation.extra_fields || {},
    survey: booth.survey || '',
    links: translation.link || [],
    isFundingKind,
    coreTechnology,
    hashtags,
    industryVerticals,
    fundingStage,
    banner: {
      verticalBanner,
      descriptionBanner,
      logoBanner,
    },
    banners: APP === 'scmp711' ? {
      left: (booth.left_banner ?? []),
      top: (booth.top_banner ?? []),
      center: (booth.center_banner ?? []),
      right: (booth.right_banner ?? []),
      bottom: (booth.bottom_banner ?? []),
    } : undefined,
    livestreams: booth.livestreams,
    videoCallUrl: booth.video_call_url,
    salesImg: booth.sales_img?.data?.full_url,
    introduction: booth.introduction,
  };

  return targetBooth;
};

const pickFiledFromDefaultTargetBooth = (targetBooth, field) => targetBooth[field];

const pickFieldFromCMSTargetBooth = (targetBooth, field, locale) => {
  switch (field) {
    case 'name': return targetBooth.name[locale];
    default: return targetBooth[field];
  }
};

const [selectIds, mapTargetBooth, pickFromBooth] = ENABLED_FEATURES.xtraCMS
  ? [selectBoothIds, (booth) => booth, pickFieldFromCMSTargetBooth]
  : [selectExhibitorIds, mapDefaultTargetBooth, pickFiledFromDefaultTargetBooth];

const BoothProfileContainer = ({
  booth,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const query = useURLSearchParams();
  const isDesktop = useMediaQuery('(min-width: 960px)');
  const largeDesktopBreakpoint = useMemo(() => {
    if (APP === 'hkstp608') {
      return 1440;
    }
    return 1200;
  }, []);
  const isLargeDesktop = useMediaQuery(`(min-width: ${largeDesktopBreakpoint}px)`);
  const { setActiveTabKey } = useContext(BoothProfileTabsContext);

  const lightboxContext = useContext(LightboxContext);
  const closeLightbox = useCallback(() => {
    lightboxContext.setUrl(null);
    lightboxContext.setCfStreamId(null);
  }, [lightboxContext]);

  const locale = useSelector(selectLocale);

  const boothIds = useSelector(selectIds);
  const boothIndex = boothIds.findIndex((id) => id === Number(booth.id) || id === booth.id);
  const prevBoothId = boothIds[boothIndex - 1];
  const nextBoothId = boothIds[boothIndex + 1];

  const targetBooth = mapTargetBooth(booth, locale);
  const boothId = pickFromBooth(targetBooth, 'id', locale);
  const boothName = pickFromBooth(targetBooth, 'name', locale);

  const handleClick = (key) => {
    switch (key) {
      case 'logo':
        if (!(booth.logoBanner?.link || booth.website)) return;
        // TODO by allen: here will had issue if is video or file, it will tracking twice
        window.trackingEvent('Booth', 'Banner 2_Click', `${boothId} - ${boothName}`);
        window.open(`http://${(booth.logoBanner?.link || booth.website).replace(/\b(https:\/\/|http:\/\/)/, '')}`, '_blank');
        break;
      case 'attachment':
        if (targetBooth.hasAttachment) {
          setActiveTabKey(targetBooth.attachmentTab);
          scrollDown();
          window.trackingEvent('Booth', 'Banner 4_Click', `${boothId} - ${boothName}`);
        }
        if (APP !== 'paloalto623' && !targetBooth?.attachments?.length) return;
        setActiveTabKey(1);
        scrollDown();
        window.trackingEvent('Booth', 'Banner 4_Click', `${boothId} - ${boothName}`);
        break;
      case 'desk':
        setActiveTabKey(0);
        setTimeout(() => scrollDown(), 0);
        window.trackingEvent('Booth', 'Banner 3_Click', `${boothId} - ${boothName}`);
        break;
      case 'centralBanner':
        // TODO by allen: here will had issue if is video or file, it will tracking twice
        window.trackingEvent('Booth', 'Banner 2_Click', `${boothId} - ${boothName}`);
        break;
      case 'poster':
        window.trackingEvent('Booth', 'Banner 1_Click', `${boothId} - ${boothName}`);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${boothName} | ${EVENT_NAME}`}</title>
        <meta name="description" content={`${boothName} of ${EVENT_NAME}.`} />
      </Helmet>
      {
        isLargeDesktop ? (
          <>
            <div className="booth-template-wrapper">
              <XtraBreadcrumbs
                paths={[
                  { name: PAGE_NAMES.lobby || t('page.lobby', 'Lobby'), to: Routes.lobby },
                  { name: PAGE_NAMES.hall || t('page.hall', 'Exhibition Hall'), to: Routes.hall },
                  { name: boothName },
                ]}
              />
              <BoothTemplateSwitch
                booth={targetBooth}
                handleClick={handleClick}
                showRepsImg
                showPlayBtn
              />
            </div>
            {prevBoothId
              && (
                <IconButton
                  className={classes.prevArrow}
                  disabled={!prevBoothId}
                  onClick={() => {
                    setActiveTabKey(0);
                    history.push({
                      pathname: Routes.booth(prevBoothId),
                      search: `?${query.toString()}`,
                    });
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
              )}
            {nextBoothId
              && (
                <IconButton
                  className={classes.nextArrow}
                  disabled={!nextBoothId}
                  onClick={() => {
                    setActiveTabKey(0);
                    history.push({
                      pathname: Routes.booth(nextBoothId),
                      search: `?${query.toString()}`,
                    });
                  }}
                >
                  <ArrowFowardIcon />
                </IconButton>
              )}
          </>
        ) : (
          <MobileProfileSwitch booth={targetBooth} />
        )
      }
      <BoothProfileTabsContainer
        booth={targetBooth}
        isDesktop={isDesktop}
      />
      <CustomLightbox
        src={lightboxContext.url}
        cfStreamId={lightboxContext.cfStreamId}
        open={!!(lightboxContext.cfStreamId || lightboxContext.url)}
        onClose={closeLightbox}
        type="video"
      />
    </>
  );
};

BoothProfileContainer.propTypes = {
  booth: PropTypes.shape().isRequired,
};

export default BoothProfileContainer;
