import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { APP, REED_APPS } from 'appenv';
import styled from 'styled-components';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAvailableLocales } from 'models/localization';
import { useTranslation } from 'react-i18next';

import { UserSessionContext } from 'components/UserSession';
import Routes from 'Routes';
import { envSwitch } from 'utils/envUtils';
import Apps from 'apps';
import HelpButton from './HelpButton';
import LocaleSelector from './LocaleSelector';

import DrawerContainer from './mobile/DrawerContainer';
import SearchButtonContainer from './mobile/SearchButtonContainer';

const RootContainer = styled.div`
  width: 100%;
  z-index: 1000;
`;

const useAppBarStyles = makeStyles(() => ({
  root: {
    zIndex: 1000,
  },
  colorPrimary: {
    color: '#484848',
    backgroundColor: '#FFF',
  },
}));

const StyledToolbar = withStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}))(Toolbar);

const StyledLogoContainer = styled.div`
  flex-grow: 1;
  display: flex;
  max-width: 100%;
`;

const StyledLink = styled(Link)`
  display: inline-flex;
`;

const LogoImage = styled.img`
  display: block;
  max-width: 100%;
  max-height: ${({ logoHeight }) => logoHeight}px;

  &:not(:last-child) {
    margin-right: 8px;
  }
`;

const PolyU618LogoImage = styled(LogoImage)`
  position: relative;
  left: -43px;
`;

export default function MobileAppBar({
  logos,
  hideDrawer,
  hideSearchBar,
}) {
  const appBarClasses = useAppBarStyles();
  const { canSee, firstVisibleRoute } = useContext(UserSessionContext);
  const availableLocales = useSelector(selectAvailableLocales);
  const { t } = useTranslation();

  const logoHeight = envSwitch([
    [Apps.PolyU618, 28],
    [Apps.Ms624, 25],
    [[Apps.SCMP711, Apps.VirtualDemo, Apps.VirtualPressCon, Apps.Sph818], 36],
    [Apps.SPH706, 50],
    [REED_APPS, 40],
    [Apps.Lighthouse710, 50],
    [Apps.Neoventure1012, 40],
    [Apps.Jetro817, 40],
  ], 56);

  const LogoImageTemplate = useMemo(() => {
    switch (APP) {
      case 'polyu618':
        return PolyU618LogoImage;
      default:
        return LogoImage;
    }
  }, []);

  const LogoLinkProps = envSwitch([
    [REED_APPS, {
      to: '//',
      component: React.forwardRef((props, ref) => (
        <a ref={ref} href={t('common:url.exhibition_website')} target="_blank" rel="noopener noreferrer">
          {props?.children}
        </a>
      )),
    }],
  ], { to: Routes[firstVisibleRoute] });

  return (
    <>
      <RootContainer>
        <AppBar
          position="static"
          className={`${appBarClasses.root} ${appBarClasses.colorPrimary}`}
          elevation={0}
        >
          <StyledToolbar>
            {!hideDrawer && <DrawerContainer />}
            <StyledLogoContainer>
              <StyledLink {...LogoLinkProps}>
                {logos.map((logoUrl, index) => <LogoImageTemplate logoHeight={logoHeight} src={logoUrl} alt="Event Logo" key={index} />)}
              </StyledLink>
            </StyledLogoContainer>
            {
              !hideSearchBar && canSee('search') && <SearchButtonContainer />
            }
            {availableLocales.length > 1 && <LocaleSelector />}
            {!canSee('lobby') && <div><HelpButton /></div>}
          </StyledToolbar>
        </AppBar>
      </RootContainer>
    </>
  );
}

MobileAppBar.propTypes = {
  logos: PropTypes.arrayOf(PropTypes.string).isRequired,
  hideSearchBar: PropTypes.bool,
  hideDrawer: PropTypes.bool,
};

MobileAppBar.defaultProps = {
  hideSearchBar: false,
  hideDrawer: false,
};
