import styled from 'styled-components';

const CompanyLink = styled.a`
  display: block;
  font-weight: bold;

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }

  & svg {
    width: 40px;
    height: 40px;
    vertical-align: middle;

    & path {
      fill: #484848;
    }
  }
`;

export default CompanyLink;
