import React, { useContext, useState, useMemo } from 'react';
import { APP, BRAND_COLOR } from 'appenv';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import { useTranslation } from 'react-i18next';

import { envSwitch } from 'utils/envUtils';
import TabsId from 'pages/booths/components/TabsId';
import LiveStreamModal from 'components/modals/livestreams/LiveStreamModal';
import convertEmbeddedLink from 'utils/embeddedLinkUtils';
import Apps from 'apps';
import BoothProfile from './BoothProfile';
import BoothDetails from './BoothDetails';
import BoothPitch from './BoothPitch';
import BoothContacts from './BoothContacts';
import Booth710Profile from './Booth710Profile';
import { BoothProfileTabsContext } from './BoothProfileTabsContext';

const Container = styled.div`
  width: ${({ isDesktop }) => (isDesktop ? '70%' : '100%')};
  height: auto;
  margin: 0 auto;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  background-color: #FFF;
  letter-spacing: 0.3px;
  padding: 1px;

  .MuiTab-wrapper {
    font-size: ${({ isDesktop }) => (isDesktop ? '24px' : '18px')};
  }

  button.MuiButtonBase-root.MuiTab-root {
    margin-right: ${({ isDesktop }) => (isDesktop ? '40px' : '20px')};
  }
`;

const StyledTabs = withStyles({
  root: {
    margin: '24px 24px 40px 24px',
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: '4px',
    '& > div': {
      maxWidth: 139,
      width: '100%',
      backgroundColor: BRAND_COLOR || '#0CA58B',
    },
  },
// eslint-disable-next-line react/jsx-props-no-spreading
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#A2AAB3',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(24),
    fontFamily: 'Lato',
    padding: '0',
    minWidth: 'unset',
    '&:focus': {
      opacity: 1,
    },
  },
  selected: {
    color: BRAND_COLOR || '#0CA58B',
  },
// eslint-disable-next-line react/jsx-props-no-spreading
}))((props) => <Tab disableRipple {...props} />);

const TabPanel = ({ children, value, index }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
  >
    {value === index && (
      children
    )}
  </div>
);

const RootContainer = styled.div`
  padding: 0 24px 24px;
`;

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

const LiveStreams = ({ livestreams }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalUrl, setModalUrl] = useState('');

  const onLiveStreamClick = (e, liveStream) => {
    e.preventDefault();
    setModalUrl(convertEmbeddedLink(liveStream.url));
    setShowModal(true);
  };

  return (
    <RootContainer>
      <LiveStreamModal onClose={() => setShowModal(false)} open={showModal} liveStreamLink={modalUrl} />
      {
        livestreams.map((it) => (
          <a
            href={it.url}
            onClick={(e) => onLiveStreamClick(e, it)}
          >
            <img
              alt="livestream"
              src={it.preview_url}
              style={({
                width: '300px',
                height: '320px',
              })}
            />
          </a>
        ))
      }
    </RootContainer>
  );
};

LiveStreams.propTypes = {
  livestreams: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const BoothProfileTabsContainer = ({
  booth,
  booth: {
    id,
    attachments,
    name,
    boothNumber,
    description,
    website,
    contacts,
    extraFields,
    coreTechnology,
    hashtags,
    industryVerticals,
    fundingStage,
    livestreams,
    introduction,
  },
  isDesktop,
}) => {
  const { t } = useTranslation();
  const { activeTabKey, setActiveTabKey, setTabsIndexMap } = useContext(BoothProfileTabsContext);
  const onActiveTabKeyChange = (event, tabKey) => {
    window.trackingEvent('Booth', `Booth_Profile_Tab_${tabKey + 1}_Click`, `${id} - ${name}`);
    setActiveTabKey(tabKey);
  };

  const solutionDescription = extraFields['Solutions Description'] || '';

  const strategicPlan = extraFields['Strategic Plan'] || '';

  const businessModel = extraFields['Business Model'] || '';

  const teamStructure = extraFields['Team Structure'] || '';

  const oneLiner = extraFields['1-liner'] || '';

  const [profileContacts, contactUsContacts] = envSwitch([
    [Apps.Jetro817, [[], contacts]],
  ], [contacts, []]);

  const tabs = useMemo(() => {
    const result = [
      {
        id: TabsId.WhoWeAre,
        title: envSwitch([
          [Apps.Lighthouse710, 'Introduction'],
          [Apps.Jetro817, 'Company'],
        ], t('booth.details', 'Who We Are')),
        Element: envSwitch([
          [Apps.Lighthouse710, () => (
            introduction
              ? <Booth710Profile name={name} introduction={introduction} />
              : (
                <BoothProfile
                  name={name}
                  boothNumber={boothNumber}
                  description={description}
                  website={website}
                  coreTechnology={coreTechnology}
                  hashtags={hashtags}
                  contacts={[]}
                  oneLiner={oneLiner}
                />
              )
          ),
          ],
        ], () => (
          <BoothProfile
            name={name}
            boothNumber={boothNumber}
            description={description}
            website={website}
            coreTechnology={coreTechnology}
            hashtags={hashtags}
            contacts={profileContacts}
            oneLiner={oneLiner}
          />
        )),
        condition: () => true,
      },
      {
        id: TabsId.OurSolution,
        title: envSwitch([], 'Our Solutions'),
        Element: () => (
          <BoothDetails
            industryVerticals={industryVerticals}
            solutionDescription={solutionDescription}
            fundingStage={fundingStage}
            businessModel={businessModel}
            strategicPlan={strategicPlan}
            teamStructure={teamStructure}
          />
        ),
        condition: () => !!solutionDescription && solutionDescription !== '',
      },
      {
        id: TabsId.Files,
        title: envSwitch([
          [['picox707', Apps.VirtualPressCon], 'Resources'],
          [Apps.Lighthouse710, 'Download'],
          [Apps.Jetro817, 'Pitch deck'],
        ], t('booth.files', 'Pitching')),
        Element: () => <BoothPitch attachments={attachments} />,
        condition: () => attachments?.length > 0,
      },
      {
        id: TabsId.Contacts,
        title: envSwitch([], 'Contact us'),
        Element: () => <BoothContacts contacts={contactUsContacts} />,
        condition: () => contactUsContacts?.length > 0,
      },
      {
        id: TabsId.LiveStreams,
        title: envSwitch([], t('booth.live_stream', 'Live Streams')),
        Element: () => <LiveStreams livestreams={livestreams} />,
        condition: () => livestreams?.length > 0,
      },
    ].filter((it) => it.condition());

    setTabsIndexMap(
      result.reduce((indexMap, tab, index) => {
        indexMap[tab.id] = index;
        return indexMap;
      }, {}),
    );

    return result;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booth.id, t]);

  return (
    <Container isDesktop={isDesktop}>
      <StyledTabs scrollButtons="off" variant="scrollable" value={activeTabKey} onChange={onActiveTabKeyChange} aria-label="styled tabs example">
        {tabs.map((it) => <StyledTab key={it.title} label={it.title} />)}
      </StyledTabs>
      <SwipeableViews
        axis="x"
        index={activeTabKey}
        disabled
        ignoreNativeScroll
        resistance
      >
        {tabs.map(({ title, Element }, index) => (
          <TabPanel key={title} value={activeTabKey} index={index}>
            <Element />
          </TabPanel>
        ))}
      </SwipeableViews>
    </Container>
  );
};

BoothProfileTabsContainer.propTypes = {
  booth: PropTypes.shape({
    id: PropTypes.number.isRequired,
    attachments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    socialMedias: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    contacts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    phoneContacts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    name: PropTypes.string.isRequired,
    boothNumber: PropTypes.string,
    description: PropTypes.string.isRequired,
    website: PropTypes.string.isRequired,
    extraFields: PropTypes.shape().isRequired,
    industryVerticals: PropTypes.shape().isRequired,
    fundingStage: PropTypes.string.isRequired,
    coreTechnology: PropTypes.string,
    hashtags: PropTypes.string,
    oneLiner: PropTypes.string,
    livestreams: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    introduction: PropTypes.string,
  }).isRequired,
  isDesktop: PropTypes.bool.isRequired,
};

export default BoothProfileTabsContainer;
