import React, { useMemo } from 'react';
import { BRAND_COLOR } from 'appenv';
import styled from 'styled-components';
import {
  ButtonLabel, RoundedButtonLink,
} from 'components/input/RoundedButton';

const ItemContainer = styled.div`
  margin: 12px 24px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;

  &:first-of-type {
    margin-top: 0;
  }
`;

const LabelContainer = styled.a`
  font-size: 16px;
  line-height: 16px;
  display: block;
  word-break: break-word;
  color: #5C8DF3;

  @media (min-width: 960px) {
    color: #000;
    text-transform: none;
    text-decoration: none;
  }
`;

const StyledButtonLabel = styled(ButtonLabel)`
  display: inline-block;
`;

const AttachmentViewButton = styled(RoundedButtonLink)`
  background: ${BRAND_COLOR || '#8B383A'};
  width: 96px;
  height: 34px;

  @media (max-width: 959px) {
    display: none;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;

  & > * {
    margin-left: 8px;
  }
`;

const BoothFile = ({
  file,
}: {
  file: {
    fileUrl: string;
  };
}) => {
  const fileName = useMemo(() => {
    if (!file.fileUrl) return '';
    return new URL(file.fileUrl)
      .searchParams.get('response-content-disposition')
      ?.split(';')
      .find((it) => it.trim().startsWith('filename='))
      ?.split('=')?.[1]
      ?.replace(/^"(.*)"$/, '$1')
      ?? 'File';
  }, [file.fileUrl]);

  if (!fileName) return null;

  return (
    <ItemContainer>
      <LabelContainer target="_blank" rel="noopener noreferrer" href={file?.fileUrl}>
        <StyledButtonLabel>
          {fileName}
        </StyledButtonLabel>
      </LabelContainer>
      <ActionsContainer>
        <AttachmentViewButton target="_blank" rel="noopener noreferrer" href={file?.fileUrl}>
          View
        </AttachmentViewButton>
      </ActionsContainer>
    </ItemContainer>
  );
};

export default BoothFile;
