import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tanShelfImg from 'assets/booths/tan-shelf.png';
import arrowImg from 'assets/booths/arrow.svg';

const StyledImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const StyledArrowImg = styled.img`
  position: absolute;
  left: 35px;
  top: 35px;
`;

const TanShelf = ({ hasAttachment }) => (
  <>
    <StyledImg src={tanShelfImg} />
    {hasAttachment && <StyledArrowImg src={arrowImg} alt="Attachments" />}
  </>
);

TanShelf.propTypes = {
  hasAttachment: PropTypes.bool.isRequired,
};

export default TanShelf;
