import React, { useCallback, useState } from 'react';

import { DesignElement, DesignElementKind } from 'models/xtra_cms/booths';
import { useSelector } from 'react-redux';
import { selectLocale } from 'models/localization';
import SliderImage from './SliderImage';
import ImageLightbox from './SliderImageLightbox';
import SliderVideo from './SliderVideo';
import SliderRichText from './SliderRichText';

const MobileBoothBanner = ({
  asset,
  isPlaying,
  index,
  onPauseVideo,
  onPlayVideo,
  onShowPopup,
  onHidePopup,
}: {
  asset: DesignElement;
  isPlaying: boolean;
  index: number;
  onPauseVideo: () => void;
  onPlayVideo: () => void;
  onShowPopup: () => void;
  onHidePopup: () => void;
}) => {
  const locale = useSelector(selectLocale);
  const [imageLightboxOpen, setImageLightboxOpen] = useState(false);

  const openImageOverlay = useCallback(() => {
    setImageLightboxOpen(true);
    if (onShowPopup) {
      onShowPopup();
    }
  }, [onShowPopup]);

  const closeImageOverlay = useCallback(() => {
    setImageLightboxOpen(false);
    if (onHidePopup) {
      onHidePopup();
    }
  }, [onHidePopup]);

  const { link } = asset as any;

  switch (asset.kind) {
    case DesignElementKind.Video:
      return (
        <SliderVideo
          thumbnail={asset.thumbnailImage[locale]?.originalImageUrl}
          renderKey={`${index}-${isPlaying === false}`}
          url={asset.video[locale].playbackUrl}
          onPauseVideo={onPauseVideo}
          onPlayVideo={onPlayVideo}
        />
      );
    case DesignElementKind.Image:
      return (
        link
          ? (
            <SliderImage
              img={asset.image[locale].originalImageUrl}
              onClick={() => window.open(link, '_blank')}
            />
          )
          : (
            <>
              <SliderImage
                img={asset.image[locale].originalImageUrl}
                onClick={openImageOverlay}
              />
              <ImageLightbox
                src={asset.image[locale].originalImageUrl}
                open={imageLightboxOpen}
                onClose={closeImageOverlay}
              />
            </>
          )
      );
    case DesignElementKind.PDF: {
      const pdfUrl = asset.pdf[locale]?.fileUrl;
      return (
        <SliderImage
          img={asset.thumbnailImage[locale].originalImageUrl}
          onClick={() => pdfUrl && window.open(pdfUrl, '_blank')}
        />
      );
    }
    case DesignElementKind.Richtext:
    default:
      return (
        <SliderRichText
          text={asset.contentRichtextHtml[locale] || ''}
        />
      );
  }
};

export default MobileBoothBanner;
