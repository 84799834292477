import React, { useEffect, useMemo, useState } from 'react';
import { BRAND_COLOR } from 'appenv';
import styled, { css } from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import { Trans } from 'react-i18next';
import moment from 'moment-timezone';
import Apps from 'apps';
import { Link } from 'react-router-dom';
import Routes from 'Routes';
import { Webinar, WebinarType } from 'models/webinars';
import { selectLocale, selectMomentLocalizer } from 'models/localization';
import { useSelector } from 'react-redux';
import { envSwitch } from 'utils/envUtils';

import { BREAKPOINT } from 'pages/webinar/constants';
import ShowMore from 'components/utils/ShowMore';
import { hexToRGB } from 'utils/colorUtils';

import { LiveWebinarMultiTracksOptions } from 'pages/webinar/live/LiveWebinarListOptions';
import WebinarRootContainer from '../../components/WebinarRootContainer';
import WatchNowButton from '../../components/WatchNowButton';
import ComingSoonButton from '../../components/ComingSoonButton';
import SessionEndedButton from '../../components/SessionEndedButton';
import SpeakerList from '../../SpeakerList';

const HeadRowContainer = styled.div<{ growthWidth: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: ${(props) => (props.growthWidth ? '100%' : 'fit-content')};

  @media (max-width: ${BREAKPOINT}px) {
    justify-content: space-between;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  & > * {
    width: 100%;
  }
`;

const DateTimeZoneContainer = styled.div<{ isMultiTrackSession: boolean }>`
  font-style: normal;
  font-weight: bold;
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${(props) => (props.isMultiTrackSession ? 'column' : 'row')};
  font-size: 14px;
  line-height: 20px;
`;

const DateContainer = styled.div`
  margin-right: 4px;
`;
const TimeZoneContainer = styled.div`
  color: #999;
`;

const LiveTag = styled.div`
  background: #F77;
  border-radius: 2px;
  padding: 3.5px 10px;
  margin-left: 16px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #FFF;
  text-transform: uppercase;
`;

const nameContainerExtraCss = envSwitch([
  [Apps.Paloalto623, css`
    color: #484848;

    &:hover {
      color: ${BRAND_COLOR || '#484848'};
    }
  `],

  [Apps.Lighthouse710, css`
    overflow: unset;
  `],
], css``);

const NameContainer = styled((
  { isMultiTrackSession, children, ...props },
) => <Link {...props}>{children}</Link>)`
  display: inline-block;
  font-style: normal;
  font-weight: bold;
  overflow: hidden;
  color: ${BRAND_COLOR || '#0CA58B'};
  text-decoration: none;
  height: ${(props) => (props.isMultiTrackSession ? '100%' : 'unset')};

  /* stylelint-disable-next-line value-keyword-case */
  ${nameContainerExtraCss}

  @media (min-width: ${BREAKPOINT}px) {
    font-size: 24px;
    line-height: 29px;
    max-height: 58px;
  }

  @media (max-width: ${BREAKPOINT}px) {
    font-size: 18px;
    line-height: 22px;
    max-height: 22px;
  }
`;

const WebinarDescriptionContainer = styled.div<any>`
  display: inline-block;
  font-size: 16px;
  line-height: 22px;
  margin: 16px 0 0 0;
  height: ${(props) => (props.isMultiTrackSession ? '100%' : 'unset')};

  & > *:first-child {
    margin-top: 0;
  }
`;

const WebinarDescriptionContent = styled.div<any>`
  max-height: 44px;
  overflow: hidden;

  & > *:first-child {
    margin-top: 0;
  }
`;

interface LiveWebinarItemProps {
  webinar: Webinar;
  multiTrackOptions: LiveWebinarMultiTracksOptions;
}

enum LiveWebinarState {
  ComingSoon, Ended, Live, Replay
}

const TopTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TopRightActionButtonWrapper = styled.div`
  width: 182px;
  flex: 0 0 auto;

  @media (max-width: ${BREAKPOINT}px) {
    display: none;
  }
`;

const BottomActionButtonWrapper = styled.div<{ isMultiTrackSession: boolean }>`
  margin-top: 16px;
  display: ${(props) => (props.isMultiTrackSession ? 'block' : 'none')};

  @media (max-width: ${BREAKPOINT}px) {
    display: block;
  }
`;

const TimeDisplayComponent = ({ webinar: { startAt, endAt }, isMultiTrackSession }: { webinar: Webinar; isMultiTrackSession: boolean }) => {
  const durationInMins = Math.abs(moment.duration(moment(startAt).diff(endAt)).asMinutes());
  const momentLocalizer = useSelector(selectMomentLocalizer);
  const startTime = momentLocalizer(startAt).format('h:mmA');
  const endTime = momentLocalizer(endAt).format('h:mmA');
  const timezoneString = `GMT${momentLocalizer(startAt).format('Z')}`;
  return (
    <DateTimeZoneContainer isMultiTrackSession={isMultiTrackSession}>
      <Trans
        defaults={`${startTime} - ${endTime} (${durationInMins} mins) (${timezoneString})`}
        i18nKey="webinar.time_format"
        components={{ b: <DateContainer />, i: <TimeZoneContainer /> }}
        values={{
          startTime, endTime, duration: durationInMins, timezone: timezoneString,
        }}
      />
    </DateTimeZoneContainer>
  );
};

const forceShow = envSwitch([
  [Apps.SCMP711, true],
], false);

const LiveWebinarItem = ({
  webinar, webinar: { startAt, endAt }, multiTrackOptions,
}: LiveWebinarItemProps) => {
  const webinarTrackId = webinar.track?.id;
  const isMultiTrackSession = useMemo(() => multiTrackOptions.multiTracksId.indexOf(webinarTrackId) !== -1, [multiTrackOptions.multiTracksId, webinarTrackId]);
  const locale: string = useSelector(selectLocale);


  const translation = webinar.translations[locale] ?? ({} as any);

  const [webinarState, setWebinarState] = useState(LiveWebinarState.ComingSoon);

  const webinarStateButtonsMap = {
    [LiveWebinarState.ComingSoon]: <ComingSoonButton />,
    [LiveWebinarState.Live]: <WatchNowButton kind={webinar.kind} id={webinar.id} name={translation.title} url={translation.url} />,
    [LiveWebinarState.Ended]: <SessionEndedButton />,
    [LiveWebinarState.Replay]: <WatchNowButton kind={WebinarType.OnDemand} id={webinar.id} name={translation.title} url={Routes.webinarSession(webinar.id)} />,
  };

  useEffect(() => {
    const update = () => {
      if (forceShow) {
        setWebinarState(LiveWebinarState.Replay);
        return;
      }
      const currentTime = moment();
      if (currentTime.isBefore(startAt)) setWebinarState(LiveWebinarState.ComingSoon);
      else if (!currentTime.isAfter(endAt)) setWebinarState(LiveWebinarState.Live);
      else setWebinarState(LiveWebinarState.Ended);
    };
    update();
    const taskId = setInterval(() => update(), 1000);
    return () => clearInterval(taskId);
  }, [startAt, endAt]);

  return (
    <WebinarRootContainer
      isMultiTrackSession={isMultiTrackSession}
      trackColor={isMultiTrackSession ? webinar.track?.color : null}
    >
      <ContentContainer>
        <div>
          <TopTitleContainer>
            <div>
              <HeadRowContainer growthWidth={isMultiTrackSession}>
                <TimeDisplayComponent webinar={webinar} isMultiTrackSession={isMultiTrackSession} />
                {webinarState === LiveWebinarState.Live && <LiveTag> Live </LiveTag>}
              </HeadRowContainer>
              <NameContainer
                isMultiTrackSession={isMultiTrackSession}
                to={Routes.webinarSession(webinar.id)}
              >
                {translation.title}
              </NameContainer>
            </div>
            {!isMultiTrackSession && (translation.url?.trim()?.length ?? 0) > 0 && (
              <TopRightActionButtonWrapper>
                {webinarStateButtonsMap[webinarState]}
              </TopRightActionButtonWrapper>
            )}
          </TopTitleContainer>
          {
            translation.description && translation.description.trim().length > 0 && (
              <WebinarDescriptionContainer isMultiTrackSession={isMultiTrackSession}>
                {
                  isMultiTrackSession ? (
                    <WebinarDescriptionContent>
                      {ReactHtmlParser(translation.description)}
                    </WebinarDescriptionContent>
                  ) : (
                    <ShowMore maxHeight={100}>
                      {ReactHtmlParser(translation.description)}
                    </ShowMore>
                  )
                }
              </WebinarDescriptionContainer>
            )
          }
        </div>
        <div>
          <SpeakerList
            webinarId={webinar.id}
            speakers={webinar.speakers}
            backgroundRGBA={[...hexToRGB(webinar.track?.color ?? '#FFFFFF'), 0.2]}
          />
          <BottomActionButtonWrapper isMultiTrackSession={isMultiTrackSession}>
            {webinarStateButtonsMap[webinarState]}
          </BottomActionButtonWrapper>
        </div>
      </ContentContainer>
    </WebinarRootContainer>
  );
};

LiveWebinarItem.defaultProps = {
  speakers: [],
  details: '',
  showTimelineIndicator: false,
  timelineIndicatorText: '',
  color: '#DBDEE2',
  backgroundColor: null,
  isMultiTrackSession: false,
};

export default LiveWebinarItem;
