import React from 'react';
import { useSelector } from 'react-redux';
import { LocalizedProduct, selectAllProductsByLocale } from 'models/products';
import CircularLoadingIndicator from 'components/indicators/CircularLoadingIndicator';
import styled from 'styled-components';
import ProductItem from 'components/product/ProductItem';

const RootContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 8px 16px;
  width: 100%;
  max-width: 100%;
`;

const ProductList = () => {
  const productList: LocalizedProduct[] = useSelector(selectAllProductsByLocale);

  if (!productList) {
    return <CircularLoadingIndicator />;
  }

  return (
    <RootContainer>
      {productList.map((product) => (
        <ProductItem product={product} key={product.id} />
      ))}
    </RootContainer>
  );
};

export default ProductList;
