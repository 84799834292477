import React from 'react';
import ReduxLocaleObserver from 'i18n/ReduxLocaleObserver';
import ReduxCMSFetchingObserver from 'models/xtra_cms/common/ReduxCMSFetchingObserver';

const ReduxObserver = () => (
  <>
    <ReduxLocaleObserver />
    <ReduxCMSFetchingObserver />
  </>
);

export default ReduxObserver;
