import React, { useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components';
import {
  EVENT_NAME,
  PAGE_NAMES,
  BRAND_COLOR,
  CUSTOM_PAGE_SOURCE,
  APP,
  REED_APPS,
} from 'appenv';
import Footer from 'components/footer/Footer';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const RootContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledIframe = styled.iframe`
  height: 100%;
  width: 100%;
  border: none;
  display: flex;
  flex-grow: 1;
`;

const FooterContainer = styled.div`
  position: absolute;
  bottom: 0;
  margin-top: 16px;
  left: 50%;
  transform: translateX(-50%);
`;

const CustomPageContainer = () => {
  const { t } = useTranslation();
  const iframeRef: React.RefObject<HTMLIFrameElement> = useRef();

  const customPageSource = useMemo(() => {
    if (REED_APPS.includes(APP as any)) return t('common:custom_page_source', CUSTOM_PAGE_SOURCE);
    return CUSTOM_PAGE_SOURCE;
  }, [t]);

  useEffect(() => {
    if (customPageSource) return;
    const iframeElement: HTMLIFrameElement = iframeRef.current;
    const content = `
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <link
          href="https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap"
          rel="stylesheet"
        >
        <meta charset="utf-8">
        <title>About EventXtra</title>
        <style>
          body {
            padding: 16px;
            font-family: Lato, sans-serif;
          }
          a {
            background-color: ${BRAND_COLOR || '#1059A8'};
            padding: 16px 24px;
            vertical-align: middle;
            display: block;
            border-radius: 8px;
            color: white;
            text-decoration: none;
          }
        </style>
      </head>
      <body>
        <h1>Example Injected HTML</h1>
        <a
          class="bookmark-link"
          target="_blank"
          href="https://www.eventxtra.com"
        >
          Visit EventXtra Website
        </a>
      </body>
    </html>
    `;
    iframeElement.contentWindow.document.open();
    iframeElement.contentWindow.document.write(content);
    iframeElement.contentWindow.document.close();
  }, [customPageSource]);

  const pageTitle = PAGE_NAMES.custom || t('common:custom_page_web_title', 'Custom Page');
  const eventName = useMemo(() => {
    if (REED_APPS.includes(APP as any)) return t('common:title', EVENT_NAME);
    return EVENT_NAME;
  }, [t]);

  const webTitle = pageTitle ? `${pageTitle} | ${eventName}` : eventName;

  const showFooter = useMemo(() => !REED_APPS.includes(APP as any), []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{webTitle}</title>
        <meta name="description" content={`${PAGE_NAMES.custom} of ${eventName}.`} />
      </Helmet>
      <RootContainer>
        <StyledIframe
          title={pageTitle}
          ref={iframeRef}
          width="100%"
          height="100%"
          src={customPageSource}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        {showFooter && (
          <FooterContainer>
            <Footer />
          </FooterContainer>
        )}
      </RootContainer>
    </>
  );
};

export default CustomPageContainer;
