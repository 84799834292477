import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateFromCMS } from 'models/xtra_cms/common/thunks';

const ReduxCMSFetchingObserver = () => {
  const dispatch = useDispatch();
  const fetching = useSelector((state: any) => state.cms.booths.fetching);
  const isFirstFetchingCompleted = useRef(false);

  useEffect(() => {
    if (!(fetching === false && !isFirstFetchingCompleted.current)) return;
    isFirstFetchingCompleted.current = true;
    const period = 1000 * 60 * 4.5;
    setInterval(() => dispatch(updateFromCMS()), period);
  }, [dispatch, fetching]);

  return null;
};

export default ReduxCMSFetchingObserver;
