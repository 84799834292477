import React, { useContext, useCallback } from 'react';
import styled from 'styled-components';
import { RONGCLOUD_CHATROOM_PREFIX, ENABLED_FEATURES } from 'appenv';
import BuildingIcon from 'components/icons/Building';
import ChatroomIcon from 'components/icons/Chatroom';
import ChatContext from 'components/chat/ChatContext';
import Routes from 'Routes';
import { selectLocale } from 'models/localization';
import { LocalizedProduct } from 'models/products';
import { useSelector } from 'react-redux';
import { selectExhibitorById } from 'models/exhibitors';
import { selectBoothById } from 'models/xtra_cms/booths';
import CompanyLink from 'components/product/CompanyLink';
import CompanyName from 'components/product/CompanyName';
import ProductImageSlider from './ProductImageSlider';

const RootContainer = styled.div`
  display: flex;
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  padding-top: 16px;

  @media (max-width: 959px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ProductSummary = styled.div`
  display: inline-block;
  flex-grow: 1;
  padding-left: 16px;

  @media (max-width: 959px) {
    padding-top: 16px;
  }
`;

const ProductName = styled.span`
  font-size: 24px;
  font-weight: bold;
`;

const ProductImageContainer = styled.div<{ hasDots: boolean }>`
  width: 100%;
  display: inline-block;
  margin-bottom: ${({ hasDots }) => (hasDots ? '70px' : 0)};
  max-width: 400px;
`;

const ChatLink = styled(CompanyLink)`
  & svg {
    height: 24px;
  }
`;

const ProductInfo = styled.div`
  padding-top: 24px;
`;

const selectById: (state: any, id: any) => any = ENABLED_FEATURES.xtraCMS
  ? selectBoothById
  : selectExhibitorById;

const ProductHighlight = ({
  product: {
    name,
    images,
    boothId,
  },
}: {
  product: LocalizedProduct;
}) => {
  const locale = useSelector(selectLocale);
  const booth = useSelector((state) => selectById(state, boothId)) || {};

  const { showChatWindow } = useContext(ChatContext);
  const localizedBoothName = ENABLED_FEATURES.xtraCMS ? (booth.name || {})[locale] : (booth.translations || {})[locale]?.name;
  const openChatWindow = useCallback(() => {
    showChatWindow(`${RONGCLOUD_CHATROOM_PREFIX}_booth_${booth.id}`, 'group', localizedBoothName);
  }, [booth.id, localizedBoothName, showChatWindow]);

  return (
    <RootContainer>
      <ProductImageContainer hasDots={images?.length > 1}>
        <ProductImageSlider
          images={images}
          rsProps={{
            dots: images?.length > 1,
          }}
        />
      </ProductImageContainer>
      <ProductSummary>
        <ProductName>
          {name}
        </ProductName>
        <ProductInfo>
          <CompanyLink href={Routes.booth(booth.id)}>
            <BuildingIcon />
            <CompanyName>{localizedBoothName}</CompanyName>
          </CompanyLink>
          <ChatLink href="#" onClick={openChatWindow}>
            <ChatroomIcon />
            <CompanyName>Chat Now</CompanyName>
          </ChatLink>
        </ProductInfo>
      </ProductSummary>
    </RootContainer>
  );
};

export default ProductHighlight;
