import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';

import ChatContext from 'components/chat/ChatContext';
import InsertInvitationIcon from 'components/icons/Calendar';
import EmailIcon from 'components/icons/EmailSmall';
import ChatroomIcon from 'components/icons/Chatroom';
import Apps from 'apps';
import { APP, RONGCLOUD_CHATROOM_PREFIX, BRAND_COLOR } from 'appenv';
import VideoCall from 'components/icons/VideoCall';
import Livestream from 'components/icons/Livestream';
import { BoothProfileTabsContext } from 'pages/booths/components/BoothProfileTabsContext';
import TabsId from 'pages/booths/components/TabsId';
import isChatroomDisabled from 'components/chat/chat_window_711/isChatroomDisabled711';

const Container = styled.div`
  min-height: 120px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  text-align: center;
`;

const TitleContainer = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
`;

const BtnGroupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 360px;
  flex-wrap: wrap;
  margin-bottom: -32px;
`;

const StyledHyperLink = styled.a`
  text-decoration: none;
  color: ${BRAND_COLOR || '#0CA58B'};

  &:visited {
    color: ${BRAND_COLOR || '#0CA58B'};
  }
`;

const BtnContainer = styled.div`
  margin-bottom: 32px;
  flex: 0 0 auto;
  min-width: 80px;
  max-width: 120px;
  cursor: pointer;
  color: ${BRAND_COLOR || '#0CA58B'} !important;

  & svg {
    fill: ${BRAND_COLOR || '#0CA58B'};
  }
`;

const BusinessPopover = ({
  boothId,
  boothName,
  emailContacts,
  meetupUrl,
  livestreams,
  videoCallUrl,
}) => {
  const { t } = useTranslation();
  const { showChatWindow } = useContext(ChatContext);

  const mailToLink = useMemo(() => {
    if (emailContacts && emailContacts.length > 0) {
      const email = emailContacts.find((eachemailContact) => (eachemailContact.type === 'email'));
      if (email?.contact) {
        switch (APP) {
          case 'scmp711':
            return `mailto:${email.contact}?subject=Enquiry from Singapore Property Festival 2020`;
          default:
            return `mailto:${email.contact}`;
        }
      }
    }
    return '';
  }, [emailContacts]);

  const openChatWindow = () => {
    window.trackingEvent('Booth', 'Group_Chat_Click', `${boothId} - ${boothName}`);
    window.trackingEvent('PublicChatroom_Enter', 'Booth_Networking_Widget_Click', `${boothId} - ${boothName}`);
    showChatWindow(`${RONGCLOUD_CHATROOM_PREFIX}_booth_${boothId}`, 'group', boothName);
  };

  const { setActiveTabKey, tabsIndexMap } = useContext(BoothProfileTabsContext);

  const goLiveStreamTab = () => {
    setActiveTabKey(tabsIndexMap[TabsId.LiveStreams]);
    document.getElementById('booth-container')
      .scroll({
        top: 1000,
        left: 0,
        behavior: 'smooth',
      });
  };

  const clickMeetup = () => {
    window.trackingEvent('Booth', 'Meetup_Click', `${boothId} - ${boothName}`);
  };

  const clickLeaveMessage = () => {
    window.trackingEvent('Booth', 'Leave a Message_Click', `${boothId} - ${boothName}`);
  };

  return (
    <Paper elevation={3}>
      <Container>
        <TitleContainer>
          {t('booth.greeting', 'Hi, how can I help you?')}
        </TitleContainer>
        <BtnGroupContainer>
          {
            // 801 chat room hide icon after live day
            ![Apps.Paloalto623, Apps.Jetro817].includes(APP) && !isChatroomDisabled() && (
              <BtnContainer onClick={openChatWindow}>
                <ChatroomIcon width={24} height={22} color={BRAND_COLOR} />
                <div>
                  {
                    APP === 'hkstp608' ? 'Public Chat' : t('booth.chat', 'Chat')
                  }
                </div>
              </BtnContainer>
            )
          }
          {
            mailToLink && (
              <StyledHyperLink href={mailToLink} target="_blank" rel="noopener noreferrer">
                <BtnContainer onClick={clickLeaveMessage}>
                  <EmailIcon width={24} height={22} color={BRAND_COLOR || '#0CA58B'} />
                  <div>{t('booth.leave_a_message', 'Leave a Message')}</div>
                </BtnContainer>
              </StyledHyperLink>
            )
          }
          {
            meetupUrl && (
              <StyledHyperLink href={meetupUrl} target="_blank" rel="noopener noreferrer">
                <BtnContainer onClick={clickMeetup}>
                  <InsertInvitationIcon width={24} height={22} color={BRAND_COLOR || '#0CA58B'} />
                  <div>{t('booth.meetup', 'Meetup')}</div>
                </BtnContainer>
              </StyledHyperLink>
            )
          }
          {
            videoCallUrl && (
              <StyledHyperLink href={videoCallUrl} target="_blank" rel="noopener noreferrer">
                <BtnContainer>
                  <VideoCall width={24} height={22} color={BRAND_COLOR || '#0CA58B'} />
                  <div>{t('booth.video_call', 'Video Call')}</div>
                </BtnContainer>
              </StyledHyperLink>
            )
          }
          {
            livestreams && livestreams.length > 0 && (
              <BtnContainer onClick={goLiveStreamTab}>
                <Livestream width={24} height={22} color={BRAND_COLOR || '#0CA58B'} />
                <div>{t('booth.live_stream', 'Livestream')}</div>
              </BtnContainer>
            )
          }
        </BtnGroupContainer>
      </Container>
    </Paper>
  );
};

BusinessPopover.propTypes = {
  boothId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  boothName: PropTypes.string.isRequired,
  emailContacts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  meetupUrl: PropTypes.string,
  livestreams: PropTypes.arrayOf(PropTypes.shape()),
  videoCallUrl: PropTypes.string,
};

BusinessPopover.defaultProps = {
  meetupUrl: '',
  livestreams: [],
  videoCallUrl: '',
};

export default BusinessPopover;
