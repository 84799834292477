import React from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

const BoothQuestionContainer = styled.div`
  padding: 12px 24px;
`;

const BoothQuestionTitle = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: #A2AAB3;
  margin-bottom: 11px;
`;

const BoothQuestionDetails = styled.div`
  line-height: 17px;
  white-space: break-spaces;
  text-align: justify;
`;

const BoothQuestion = ({ question, answer }: {
  question: string;
  answer: string;
}) => {
  const shouldShow = question && answer && new DOMParser().parseFromString(answer, 'text/html')?.body?.textContent;
  if (!shouldShow) return null;
  return (
    question?.trim() && answer?.trim() && (
      <BoothQuestionContainer>
        <BoothQuestionTitle>
          {ReactHtmlParser(question)}
        </BoothQuestionTitle>
        <BoothQuestionDetails>
          {ReactHtmlParser(answer)}
        </BoothQuestionDetails>
      </BoothQuestionContainer>
    )
  );
};

export default BoothQuestion;
