import moment from 'moment';
import { useContext, useEffect, useRef } from 'react';
import { batch, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import Apps from 'apps';
import { RONGCLOUD_CHATROOM_PREFIX, APP } from 'appenv';
import { UserSessionContext } from 'components/UserSession';
import { updateConversation } from 'models/conversations';
import { addMessage } from 'models/messages';
import groupChatJoin from 'network/groupChatJoin';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

declare global {
  interface Window {
    RongIMLib: any;
    RongIMClient: any;
  }
}

type useAutoJoinMsgDataRef = {
  authToken: string;
  name: string;
  tag: string;
  description: string;
  dispatch: Dispatch;
  t: TFunction;
  isEnabledAutoMsg: () => boolean;
}

const useAutoJoinMsg = (boothId: string) => {
  const translation = useTranslation();
  const { userSession } = useContext(UserSessionContext);
  const dispatcher = useDispatch();
  const ref = useRef<useAutoJoinMsgDataRef>(null);
  ref.current = {
    authToken: userSession?.authToken,
    name: userSession?.name,
    // eslint-disable-next-line camelcase
    tag: userSession?.customAttendeeFields?.vexpo_chat_tag,
    // eslint-disable-next-line camelcase
    description: userSession?.customAttendeeFields?.vexpo_chat_user_description,
    dispatch: dispatcher,
    t: translation.t,
    isEnabledAutoMsg: () => {
      if (APP === 'scmp711' && userSession?.role !== 'attendee') {
        return false;
      } // scmp711 only

      switch (APP) {
        case Apps.Jetro817:
        case 'hkstp608':
        case 'paloalto623':
        case 'ms624':
        case 'picox707':
          return false;
        default:
          return true;
      }
    },
  };

  useEffect(() => {
    if (!boothId) return;

    async function sendAutoJoinMsg(groupId: string) {
      const {
        authToken, name, tag, description, dispatch, t,
      } = ref.current;
      const success = await groupChatJoin(authToken, groupId);
      if (success) {
        const autoMsg = new window.RongIMLib.TextMessage({
          content: t('chat.has_joined'),
          user: {
            autoSent: true,
            name,
            tag,
            description,
          },
        });
        const conversationType = window.RongIMLib.ConversationType.GROUP;

        window.RongIMClient.getInstance().sendMessage(conversationType, groupId, autoMsg, {
          onSuccess: (message) => {
            message.id = message.messageUId || message.messageId;
            const messageStr = JSON.stringify(message);
            const messageJSON = JSON.parse(messageStr);
            batch(() => {
              dispatch(addMessage(messageJSON));
              dispatch(updateConversation({
                id: groupId,
                changes: {
                  latestMessage: messageJSON,
                },
              }));
            });
          },
          onError: () => { console.error('Auto join error'); },
        });

        dispatch(updateConversation({
          id: groupId,
          changes: {
            sentTime: moment().valueOf(),
          },
        }));
      }
    }

    if (ref.current.isEnabledAutoMsg()) {
      sendAutoJoinMsg(`${RONGCLOUD_CHATROOM_PREFIX}_booth_${boothId}`);
    }
  }, [boothId]);
};

export default useAutoJoinMsg;
