const bannerUtils = {
  toImage: (src: string, href = null) => ({
    src,
    href,
    type: 'image',
  }),
};

export const en = {
  entry: {
    to_exhibition_hall: 'Enter Exhibition Hall',
    how_to_use_oms: '<b>How to utilize</b><b>virtual expo?</b>',
  },
  banners: {
    left: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/c63d24f7-4b80-497d-b3fc-bde2b22f1b7a.png',
      ),
    ],
    right: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/c63d24f7-4b80-497d-b3fc-bde2b22f1b7a.png',
      ),
    ],
    middle: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/c63d24f7-4b80-497d-b3fc-bde2b22f1b7a.png',
        'https://www.edix-expo.jp/en-gb.html',
      ),
    ],
    mobile: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/c63d24f7-4b80-497d-b3fc-bde2b22f1b7a.png',
        'https://www.edix-expo.jp/en-gb.html',
      ),
    ],
  },
};

export const ja = {
  entry: {
    to_exhibition_hall: '展示会場はこちら',
    how_to_use_oms: '<b>はじめに</b><i>オンライン来場・商談サービスの使い方</i>',
  },
  banners: {
    left: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/21b301d7-338f-4a88-aacd-512ee2b848ca.png',
      ),
    ],
    right: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/21b301d7-338f-4a88-aacd-512ee2b848ca.png',
      ),
    ],
    middle: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/21b301d7-338f-4a88-aacd-512ee2b848ca.png',
        'https://www.edix-expo.jp/ja-jp.html',
      ),
    ],
    mobile: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/21b301d7-338f-4a88-aacd-512ee2b848ca.png',
        'https://www.edix-expo.jp/ja-jp.html',
      ),
    ],
  },
};
