import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { WebinarType } from 'models/webinars';
import WatchNowIcon from 'components/icons/WatchNow';
import ActionButton from './ActionButton';

const StyledBtnText = styled.div`
  margin-left: 10px;
`;

const WatchNowButton = ({
  id,
  name,
  url,
  kind,
}) => {
  const { t } = useTranslation();
  const onWatchNowClick = useCallback(() => {
    // eslint-disable-next-line no-new
    new Promise((resolve) => {
      window.trackingEvent('MainMenu_Webinar', 'PlayWebinarVideo', `Webinar-${id}-${name}_${url || ''}`);
      resolve(null);
    });
  }, [id, name, url]);

  return (
    <ActionButton
      onClick={onWatchNowClick}
      href={url}
      rel={kind === WebinarType.Live ? 'noopener noreferrer' : undefined}
      target={kind === WebinarType.Live ? '_blank' : undefined}
      component="a"
    >
      <WatchNowIcon color="#FFF" />
      <StyledBtnText>
        {kind === WebinarType.Live ? t('webinar.session.live') : t('webinar.session.play')}
      </StyledBtnText>
    </ActionButton>
  );
};

WatchNowButton.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  kind: PropTypes.oneOf([WebinarType.Live, WebinarType.OnDemand]).isRequired,
};

export default WatchNowButton;
