import { EVENT_TOKEN } from 'appenv';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';
import { envSwitch } from 'utils/envUtils';
import Apps from 'apps';
import { selectLocale } from './localization';

export interface ProductImage {
  originalImageUrl: string;
  previewImageUrl: string;
  alt: string;
}

interface AnswerField {
  title: string;
  content: string;
}
type Tags = string[];

type LocalizableField<T> = Record<string, T>;
type LocalizableString = LocalizableField<string>;
type LocalizableImage = LocalizableField<ProductImage>;
type LocalizableTags = LocalizableField<Tags>;
type LocalizableAnswer = LocalizableField<Record<string, AnswerField>>;

export interface Product {
  id: string;
  name: LocalizableString;
  oneLiner: LocalizableString;
  image: LocalizableImage;
  images: LocalizableImage[];
  tags: LocalizableTags;
  boothId: string;
  eventToken: string;
  details: LocalizableAnswer;
}

export interface LocalizedProduct {
  id: string;
  name: string;
  oneLiner: string;
  image: ProductImage;
  images: ProductImage[];
  tags: Tags;
  boothId: string;
  eventToken: string;
  details: Record<string, AnswerField>;
}

const PRODUCT_API_URL = process.env.NODE_ENV === 'production' ? 'https://vexpo-product-api.eventxtra.com' : 'http://localhost:5000';

const getProductsById = async (productId: string): Promise<Record<string, any>> => {
  const PRODUCT_URL = `${PRODUCT_API_URL}/products/${productId}`;
  const productResponse = await fetch(PRODUCT_URL);
  if (productResponse.status === 200) {
    const product = await productResponse.json();
    delete product.createdOn;
    delete product.lastModifiedOn;
    return product;
  }
  throw new Error('Product is not found');
};

const eventToken = envSwitch([
  [Apps.Jetro817, 'gbtyes6dGZHmhdKTiu4RYxyw'],
  [Apps.Reed902, 'P5fqMS24CCsFk8sZ4HhdJQCV'],
], EVENT_TOKEN);

const getEventProductList = async () => {
  const PRODUCT_URL = `${PRODUCT_API_URL}/products?filter={"eventToken":"${eventToken}"}&range=[0,30]`;
  const productResponse = await fetch(PRODUCT_URL);
  let productList = await productResponse.json();
  productList = productList.map((product) => {
    delete product.createdOn;
    delete product.lastModifiedOn;
    return product;
  });
  return productList;
};

const productsAdapter = createEntityAdapter<Product>({
  sortComparer: (a, b) => a.name?.en.localeCompare(b.name?.en),
});
export const {
  selectAll: selectAllProducts,
  selectById: selectProductById,
} = productsAdapter.getSelectors((state: any) => state.products);

const localizableFields = {
  boothId: false,
  details: true,
  eventToken: false,
  id: false,
  images: true,
  name: true,
  providerId: false,
  shortDescription: true,
  tags: true,
};

const getLocalizedProduct = (product: Product, locale: string) => {
  const localizedProduct = {} as any;
  const localizeHelper = (obj: any, key: string) => {
    if (key in localizableFields && localizableFields[key]) {
      if (key === 'details') {
        return Object.keys(obj).reduce((acc, curr) => {
          acc[curr] = obj[curr][locale];
          return acc;
        }, {});
      }
      if (key === 'images') {
        [localizedProduct.image] = obj[locale];
      }
      return obj[locale];
    }
    return obj;
  };
  Object.keys(product || {}).forEach((key) => {
    localizedProduct[key] = localizeHelper(product[key], key);
  });
  return localizedProduct;
};

export const selectAllProductsByLocale = createSelector(
  [selectAllProducts, selectLocale],
  (products: Product[], locale: string): LocalizedProduct[] => products
    .map((product) => getLocalizedProduct(product, locale)),
);

export const selectAllProductsByBoothId = (boothId: string) => createSelector(
  [selectAllProductsByLocale],
  (products: LocalizedProduct[]): LocalizedProduct[] => (products || [])
    .filter((product: LocalizedProduct) => product.boothId === boothId),
);

export const selectProductByIdAndLocale = createSelector(
  [selectProductById, selectLocale],
  getLocalizedProduct,
);

export const fetchProducts = createAsyncThunk(
  'products/fetchAll',
  async () => {
    try {
      const result = await getEventProductList();
      return result;
    } catch (error) {
      console.error(error);
    }
    return {};
  },
);

export const fetchProductsById = createAsyncThunk(
  'products/fetchById',
  async (productId: string) => {
    try {
      const result = await getProductsById(productId);
      return result;
    } catch (error) {
      console.error(error);
    }
    return {};
  },
);

export const productSlice = createSlice({
  name: 'products',
  initialState: productsAdapter.getInitialState({ fetching: true }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.pending, (state: any) => {
      state.fetching = true;
    });
    builder.addCase(fetchProducts.fulfilled, (state: any, action) => {
      productsAdapter.upsertMany(state, action.payload);
      state.fetching = false;
    });
    builder.addCase(fetchProductsById.fulfilled, (state: any, action) => {
      productsAdapter.upsertOne(state, action.payload as Product);
      state.fetching = false;
    });
    builder.addCase(fetchProductsById.pending, (state: any) => {
      state.fetching = true;
    });
    builder.addCase(fetchProductsById.rejected, (state: any) => {
      state.fetching = false;
    });
    builder.addCase(fetchProducts.rejected, (state: any) => {
      state.fetching = false;
    });
  },
});

export default productSlice.reducer;
