/* eslint-disable import/prefer-default-export */
/* eslint-disable no-underscore-dangle */
import { createAsyncThunk } from '@reduxjs/toolkit';
import eventByToken from 'network/eventByToken';
import boothById from 'network/boothById';
import { normalize, schema } from 'normalizr';
import { initialState } from 'models/localization';

const mapLocalizable = (obj) => obj?.values
  ?.reduce((sum, { locale, value }) => ({ ...sum, [locale]: value }), {}) || {};

const mapQuestionContentElement = (node: any) => {
  const updated = (() => {
    switch (node?.__typename) {
      case 'BoothRichtextContentElement':
        return {
          contentRichtextHtml: mapLocalizable(node.contentRichtextHtml || {}),
        };
      case 'BoothImageContentElement':
        return {
          image: mapLocalizable(node.image || {}),
        };
      case 'BoothVideoContentElement':
        return {
          videoFile: mapLocalizable(node.videoFile || {}),
        };
      case 'BoothFileContentElement':
        return {
          file: mapLocalizable(node.file || {}),
        };
      case 'BoothQuestionContentElement':
        return {
          question: mapLocalizable(node.question || {}),
          answer: mapQuestionContentElement(node.answer),
        };
      default: return {};
    }
  })();
  return { ...node, ...updated };
};

const boothEntity = new schema.Entity(
  'booths',
  {},
  {
    processStrategy(entity) {
      const designElements = entity.designElements.nodes
        .map((it) => ({
          ...it,
          image: mapLocalizable(it.image || {}),
          video: mapLocalizable(it.video || {}),
          contentRichtextHtml: mapLocalizable(it.contentRichtextHtml || {}),
          thumbnailImage: mapLocalizable(it.thumbnailImage || {}),
          pdf: mapLocalizable(it.pdf || {}),
        }));
      const tabs = entity.rootContentElement.tabs.nodes
        .map((it) => ({
          ...it,
          title: mapLocalizable(it.title || {}),
          body: it.body.nodes.map((node) => mapQuestionContentElement(node)),
        }));
      const categories = entity.categories.nodes
        .map((category) => ({ ...category, name: mapLocalizable(category.name || {}) }));

      let attachmentTab = -1;
      const hasAttachment = entity.rootContentElement.tabs.nodes
        .some(({ body: { nodes } }, index) => (
          nodes.some((element) => {
            attachmentTab = index;
            return element.__typename === 'BoothFileContentElement';
          })
        ));
      return {
        ...entity,
        designElements,
        categories,
        description: mapLocalizable(entity.description || {}),
        name: mapLocalizable(entity.name || {}),
        logo: mapLocalizable(entity.logo || {}),
        tier: {
          ...entity.tier,
          name: mapLocalizable(entity.tier.name || {}),
        },
        rootContentElement: {
          ...entity.rootContentElement,
          tabs,
        },
        hasAttachment,
        attachmentTab,
      };
    },
  },
);

const hallEntity = new schema.Entity(
  'halls',
  {
    booths: new schema.Array(boothEntity),
  },
  {
    processStrategy(entity) {
      return {
        ...entity,
        name: mapLocalizable(entity.name),
        booths: entity.booths.nodes,
      };
    },
  },
);

export const fetchCMS = createAsyncThunk(
  'cms/fetch',
  async () => {
    try {
      const response = await eventByToken(initialState.availableLocales);
      const { entities } = normalize(response.halls.nodes, [hallEntity]);
      return entities;
    } catch (error) {
      console.error(error);
    }
    return {};
  },
);

export const updateFromCMS = createAsyncThunk(
  'cms/update',
  async () => {
    try {
      const response = await eventByToken(initialState.availableLocales);
      const { entities } = normalize(response.halls.nodes, [hallEntity]);
      return entities;
    } catch (error) {
      console.error(error);
    }
    return {};
  },
);

export const fetchBoothById = createAsyncThunk(
  'cms/fetchBoothById',
  async (boothId: string) => {
    try {
      const response = await boothById(boothId, initialState.availableLocales);
      const { entities } = normalize([response], [boothEntity]);
      return entities;
    } catch (error) {
      console.error(error);
    }
    return {};
  },
);
