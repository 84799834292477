import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import VideocamIcon from '@material-ui/icons/Videocam';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import UserAvatar from 'components/UserAvatar';
import { useTranslation, Trans } from 'react-i18next';

const DesktopContainer = styled.div`
  padding: 12px;
  background-color: #FFF;
  width: 550px;
  height: fit-content;
  position: relative;
  border-radius: 12px;
  outline: 0;
`;

const FlexContaner = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => (flexDirection || 'row')};
  justify-content: ${({ justifyContent }) => (justifyContent || 'flex-start')};
  align-items: ${({ alignItems }) => (alignItems || 'flex-start')};
`;

const UserAvatarContainer = styled.div`
  margin-right: 12px;
`;

const CallHintText = styled.div`
  color: #999;
  line-height: 40px;
`;

const ButtonGroupContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  font-size: 16px;
`;

const Header = styled.div`
  margin-bottom: 20px;
  font-weight: 800;
`;

const LinkContainer = styled.div`
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.3px;
  white-space: pre-wrap;

  & > .launch-hint-header {
    font-size: 24px;
  }
`;

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '10 !important',
  },
  close: {
    position: 'absolute',
    right: '12px',
    top: '0px',
  },
  decline: {
    marginRight: '12px',
    color: '#5c8df3',
  },
  accept: {
    backgroundColor: '#5c8df3',
  },
}));

const RtcChatDesktop = ({
  rtcCallingInfo,
  rtcCallingStatus,
  decline,
  accept,
  cancel,
  videoCallUrl,
  close,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const renderCallerName = () => {
    switch (rtcCallingStatus) {
      case 'inviter_connecting':
      case 'waiting_for_response':
        return (
          <FlexContaner flexDirection="column">
            <Trans
              i18nKey="call.waiting_response"
              values={{ name: rtcCallingInfo.name }}
              components={{ b: <div /> }}
              defaults="<b>Waiting for response from %{name}</b>"
            />
            <CallHintText>{t('call.start_soon_inviter')}</CallHintText>
          </FlexContaner>
        );
      case 'invitee_connecting':
      case 'being_called':
        return (
          <FlexContaner flexDirection="column">
            <Trans
              i18nKey="call.calling_you"
              values={{ name: rtcCallingInfo.name }}
              components={{ b: <div /> }}
              defaults="<b>%{name} is calling you</b>"
            />
            <CallHintText>{t('call.start_soon')}</CallHintText>
          </FlexContaner>
        );
      default:
        return <h2>{rtcCallingInfo.name}</h2>;
    }
  };

  return (
    <Modal
      open={rtcCallingStatus !== 'available'}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
    >
      <DesktopContainer>
        {
          ['inviter_connecting', 'waiting_for_response'].includes(rtcCallingStatus) && (
            <Trans
              i18nKey="call.calling"
              components={{ b: <Header /> }}
              defaults="<b>Calling...</b>"
            />
          )
        }
        {
          ['invitee_connecting', 'being_called'].includes(rtcCallingStatus) && (
            <Trans
              i18nKey="call.incoming_video_call"
              components={{ b: <Header /> }}
              defaults="<b>Incoming video call</b>"
            />
          )
        }
        {
          ['inviter_connecting', 'invitee_connecting'].includes(rtcCallingStatus) && (
            <IconButton aria-label="delete" onClick={close} className={classes.close}>
              <CloseIcon />
            </IconButton>
          )
        }
        <FlexContaner>
          <UserAvatarContainer>
            <UserAvatar
              name={rtcCallingInfo.name || 'user'}
            />
          </UserAvatarContainer>
          {renderCallerName()}
        </FlexContaner>
        {
          rtcCallingStatus === 'waiting_for_response' && (
            <ButtonGroupContainer>
              <Button color="primary" onClick={cancel} className={classes.decline}>
                {t('call.cancel', 'Cancel')}
              </Button>
            </ButtonGroupContainer>
          )
        }
        {
          rtcCallingStatus === 'being_called' && (
            <ButtonGroupContainer>
              <Button color="primary" onClick={decline} className={classes.decline}>
                {t('call.decline', 'Decline')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.accept}
                startIcon={<VideocamIcon />}
                size="small"
                onClick={accept}
              >
                {t('call.accept', 'Accept')}
              </Button>
            </ButtonGroupContainer>
          )
        }
        {
          ['inviter_connecting', 'invitee_connecting'].includes(rtcCallingStatus) && (
            <FlexContaner alignItems="center" flexDirection="column">
              <LinkContainer>
                <div className="launch-hint-header">
                  {t('call.launching_video_room', 'Launching video chatroom...')}
                </div>
                <div>
                  <Trans
                    i18nKey="call.open_video_call_desktop"
                    values={{ videoCallUrl }}
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    components={{ b: <a /> }}
                    defaults="If the room is not open,  <b href=%{videoCallUrl} target='_blank'>click here<\/b>  to open room"
                  />
                </div>
              </LinkContainer>
            </FlexContaner>
          )
        }
      </DesktopContainer>
    </Modal>
  );
};

RtcChatDesktop.propTypes = {
  rtcCallingInfo: PropTypes.shape().isRequired,
  rtcCallingStatus: PropTypes.string.isRequired,
  decline: PropTypes.func.isRequired,
  accept: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  videoCallUrl: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
};

export default RtcChatDesktop;
