import React, {
  Suspense,
  LazyExoticComponent,
  ReactElement,
} from 'react';
import { APP, PAGE_NAMES, BRAND_COLOR } from 'appenv';
import Routes from 'Routes';
import styled from 'styled-components';
import XtraBreadcrumbs from 'components/XtraBreadcrumbs';
import { useTranslation } from 'react-i18next';

let HallPageHeader: LazyExoticComponent<() => ReactElement> | (() => ReactElement) = () => <div />;
if (APP === 'polyu618') {
  HallPageHeader = React.lazy(() => import('../PolyU618Header'));
}

const FilteringResultsText = styled.div`
  flex-basis: 100%;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.408px;
  text-transform: capitalize;
  color: ${BRAND_COLOR || '#0CA58B'};
  padding: 24px;
`;

const HeaderComponentWrapper = styled.div`
  width: 100%;
  position: absolute;
  border-radius: 0 !important;
  top: -${({ headerOffset }: { headerOffset: number }) => headerOffset}px;
  left: 0;
`;

const hideBreadcrumbsList = ['scmp711'];

const HeaderComponent = ({
  isDesktop,
  isFiltered,
  filterLength,
  headerOffset,
  hasFilter,
  className = '',
}: {
  isDesktop: boolean;
  isFiltered: boolean;
  filterLength: number;
  headerOffset: number;
  hasFilter: boolean;
  className?: string;
}): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <HeaderComponentWrapper className={className} headerOffset={headerOffset}>
      <Suspense fallback={null}>
        {!(hasFilter && isDesktop) && !hideBreadcrumbsList.includes(APP) && (
        <XtraBreadcrumbs paths={[{ name: PAGE_NAMES.lobby || t('page.lobby', 'Lobby'), to: Routes.lobby }, { name: PAGE_NAMES.hall || t('page.hall', 'Exhibition Hall') }]} />
        )}
        <HallPageHeader />
        {(isDesktop && isFiltered && hasFilter) && (
        <FilteringResultsText>
          {t('filter.total_filtered_results', 'Filtering results: %{total}', { total: filterLength })}
        </FilteringResultsText>
        )}
      </Suspense>
    </HeaderComponentWrapper>
  );
};

export default HeaderComponent;
