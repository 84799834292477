
export const en = {
  custom_page_source: 'https://www.reedexpo.co.jp/en/online_visitormanual/',
  custom_page_web_title: 'How to utilize virtual expo?',
};

export const ja = {
  custom_page_source: 'https://www.reedexpo.co.jp/online_visitormanual/',
  custom_page_web_title: '',
};
