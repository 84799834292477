import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectLocale, selectAvailableLocales } from 'models/localization';

declare global {
  interface Window {
    $zopim?: any;
  }
}

const ReduxLocaleObserver = () => {
  const locale = useSelector(selectLocale);
  const availableLocales = useSelector(selectAvailableLocales);
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.options.fallbackLng = availableLocales;
  }, [availableLocales, i18n]);

  useEffect(() => {
    if (i18n.language !== locale) i18n.changeLanguage(locale);
    const { $zopim } = window;
    if (!$zopim) return;
    $zopim(() => {
      $zopim.livechat.setLanguage(locale);
    });
  }, [locale, i18n]);

  return null;
};

export default ReduxLocaleObserver;
