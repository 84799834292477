import React, {
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react';
import { APP } from 'appenv';
import styled from 'styled-components';
import { FixedSizeList as List } from 'react-window';
import Footer from 'components/footer/Footer';
import BackToTopButton from 'components/BackToTopButton';

import HeaderComponent from './HeaderComponent';
import FooterWrapper from './FooterWrapper';
import ListRow from './ListRow';

const NoExhibitorHeaderComponentWrapper = styled.div`
  height: 100%;
  width: 100%;
  flex: 1 1 auto;
  display: flex;
`;

const NoExhibitorFooterWrapper = styled(FooterWrapper)`
  width: 100%;
  height: 90px;
  position: absolute;
`;

const boothPreviewSize: {
  containerX: number;
  marginX: number;
  x: number;
  containerY: number;
  marginY: number;
  y: number;
} = {
  containerX: 330,
  marginX: 24,
  x: 330 + 24 * 2,
  containerY: 270,
  marginY: 20,
  y: 270 + 20 * 2,
};

const maxBoothPerRowEvent = ['paloalto623', 'ms624'];

const hideBreadcrumbsList = ['scmp711'];

const ListComponent = ({
  height,
  width,
  isDesktop,
  isFiltered,
  hasFilter,
  exhibitors,
}: {
  height: number;
  width: number;
  isDesktop: boolean;
  isFiltered: boolean;
  hasFilter: boolean;
  exhibitors: Record<any, any>[];
}) => {
  const responsiveWidth: number = useMemo(() => (
    width >= boothPreviewSize.x ? boothPreviewSize.x : width
  ), [width]);

  const itemsPerRow: number = useMemo(() => {
    if (maxBoothPerRowEvent.includes(APP)) {
      return Math.min(4, (Math.floor(width / responsiveWidth)));
    }
    return (Math.floor(width / responsiveWidth));
  }, [width, responsiveWidth]);

  const rowCount: number = useMemo(() => (
    Math.ceil(exhibitors.length / itemsPerRow)
  ), [exhibitors.length, itemsPerRow]);

  const mobileFilterOffset = useMemo(() => (
    (!isDesktop && APP === 'hkstp608') ? 50 : 0
  ), [isDesktop]);

  const headerOffset: number = useMemo(() => {
    const noFilterBreadcrumbsTop = (
      !(isDesktop && hasFilter) && !hideBreadcrumbsList.includes(APP) ? 70 : 0
    );
    const filterResultsTop = ((isDesktop && isFiltered) ? 66 : 0);
    const headerWidth: number = (width > 700 ? 139 : ((139 / 700) * width));
    return (APP === 'polyu618' ? headerWidth : 0) + filterResultsTop + noFilterBreadcrumbsTop;
  }, [width, isDesktop, isFiltered, hasFilter]);

  const containerHeight = useMemo(() => (height - mobileFilterOffset), [height, mobileFilterOffset]);

  const [scrollableTarget, setScrollableTarget] = useState();
  const scrollableTargetRef = useRef();

  useEffect(() => {
    setScrollableTarget(scrollableTargetRef.current);
  }, []);

  if (isFiltered && !exhibitors.length) {
    return (
      <NoExhibitorHeaderComponentWrapper>
        <HeaderComponent
          isDesktop={isDesktop}
          isFiltered={isFiltered}
          filterLength={exhibitors.length}
          headerOffset={0}
          hasFilter={hasFilter}
        />
        <NoExhibitorFooterWrapper offsetTop={containerHeight - 90}>
          <Footer hashState="/hall" />
        </NoExhibitorFooterWrapper>
      </NoExhibitorHeaderComponentWrapper>
    );
  }

  return (
    <>
      <List
        itemCount={rowCount}
        itemSize={boothPreviewSize.y}
        height={containerHeight}
        width={width}
        itemData={exhibitors}
        style={{ overflowX: 'hidden' }}
        outerRef={scrollableTargetRef}
      >
        {(props: any) => (
          <ListRow
            {...props}
            headerOffset={headerOffset}
            itemsPerRow={itemsPerRow}
            hasFilter={hasFilter}
            /* eslint-disable-next-line react/prop-types */
            key={props.index}
            containerHeight={containerHeight}
            rowCount={rowCount}
            isFiltered={isFiltered}
            isDesktop={isDesktop}
          />
        )}
      </List>
      {scrollableTarget && <BackToTopButton scrollableTarget={scrollableTarget} />}
    </>
  );
};

export default ListComponent;
