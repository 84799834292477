import React, { useState, useEffect, useCallback } from 'react';
import { APP } from 'appenv';
import styled from 'styled-components';
import Slider from 'react-slick';

import CustomSlide from 'components/mobileBanner/CustomSlide';
import SliderBulletList from 'components/mobileBanner/SliderBulletList';
import SliderBullet from 'components/mobileBanner/SliderBullet';
import SliderBulletContainer from 'components/mobileBanner/SliderBulletContainer';
import { DesignElement } from 'models/xtra_cms/booths';
import MobileBoothBanner from './SliderBanner';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const RootContainer = styled.div`
  padding: 16px 16px 0;
`;

const StyledSlider = styled(Slider)<{ width: string }>`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;

  .slick-track {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slick-slide {
    height: 100%;
    background-color: #FFF;
  }

  & img {
    width: ${({ width }) => (width)};
  }
`;

interface Sortable {
  sort: number;
}

const MobileBoothBannerSlider = ({ assets }: { assets: DesignElement[] }) => {
  const [activeIndex, setActiveIndex]: [number, React.Dispatch<number>] = useState(0);
  const [videoStateMap, setVideoStateMap]: [
    Record<string | number, boolean>,
    React.Dispatch<React.SetStateAction<Record<string, boolean>>>
  ] = useState({});

  const sliderRef: React.RefObject<Slider> = React.createRef();
  const videoKeys = Object.keys(videoStateMap || {});
  const anyVideosPlaying = !!videoKeys.length && !videoKeys.every((vidIndex) => !videoStateMap[vidIndex]);

  const setVideoPlayingState = useCallback((state: boolean, index: number) => {
    if (state && sliderRef.current) {
      sliderRef.current.slickPause();
    }
    setVideoStateMap({
      ...videoStateMap,
      [index]: state,
    });
  }, [sliderRef, videoStateMap]);

  const resumeSlider = useCallback(() => sliderRef.current.slickPlay(), [sliderRef]);
  const pauseSlider = useCallback(() => sliderRef.current.slickPause(), [sliderRef]);

  useEffect(() => {
    if (!sliderRef.current) return;
    if (anyVideosPlaying) {
      pauseSlider();
    } else {
      resumeSlider();
    }
  }, [anyVideosPlaying, pauseSlider, resumeSlider, sliderRef]);

  const stopAllVideos = useCallback(() => {
    if (!anyVideosPlaying) return;
    setVideoStateMap((currMap: Record<string, boolean>) => Object.keys(currMap).reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {}));
    setTimeout(() => setVideoStateMap({}), 0);
  }, [anyVideosPlaying]);

  const onSlideChange = useCallback((index: number) => {
    setActiveIndex(index);
    stopAllVideos();
  }, [stopAllVideos]);

  const resetVideoState = useCallback(() => setVideoStateMap({}), [setVideoStateMap]);

  const onPlayVideo = useCallback((index: number) => {
    setVideoPlayingState(true, index);
  }, [setVideoPlayingState]);

  const onPauseVideo = useCallback((index: number) => {
    setVideoPlayingState(false, index);
    resetVideoState();
  }, [setVideoPlayingState, resetVideoState]);

  return (
    <RootContainer>
      <StyledSlider
        arrows={false}
        dots={false}
        afterChange={onSlideChange}
        pauseOnHover
        ref={sliderRef}
        width={APP === 'paloalto623' ? '100%' : 'auto'}
      >
        {assets.map((asset, index) => (
          <CustomSlide key={index}>
            <MobileBoothBanner
              asset={asset}
              index={index}
              isPlaying={videoStateMap[index]}
              onShowPopup={pauseSlider}
              onHidePopup={resumeSlider}
              onPlayVideo={() => onPlayVideo(index)}
              onPauseVideo={() => onPauseVideo(index)}
            />
          </CustomSlide>
        ))}
      </StyledSlider>
      <SliderBulletList>
        {assets.map((_, index) => (
          <SliderBulletContainer key={index}>
            <SliderBullet active={index === activeIndex} />
          </SliderBulletContainer>
        ))}
      </SliderBulletList>
    </RootContainer>
  );
};

export default MobileBoothBannerSlider;
