import React, { useEffect } from 'react';
import { EVENT_NAME } from 'appenv';
import styled from 'styled-components';
import Footer from 'components/footer/Footer';
import { Helmet } from 'react-helmet';
import EmptyState from 'components/EmptyState';
import CircularLoadingIndicator from 'components/indicators/CircularLoadingIndicator';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectProductByIdAndLocale, fetchProductsById, LocalizedProduct } from 'models/products';
import { ProductDetailTabsContextStateProvider } from './ProductDetailTabsContext';
import ProductHighlight from './ProductHighlight';
import ProductDescriptionContainer from './ProductDescriptionContainer';

const RootContainer = styled.div`
  display: flex;
  margin: 0 auto;
  width: 100%;
  min-height: 100%;
  padding: 16px;
  overflow-x: hidden;
  flex-direction: column;

  @media (max-width: 959px) {
    padding: 0;
  }
`;

const FooterWrapper = styled.div`
  padding-top: 20px;
`;

const ProductDetailPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const productId = history.location.pathname.split('/')[2];

  useEffect(() => {
    dispatch(fetchProductsById(productId));
  }, [productId, dispatch]);

  const product: LocalizedProduct = useSelector((state) => (selectProductByIdAndLocale(state, productId))) ?? {} as LocalizedProduct;
  const fetchingProducts = useSelector((state) => (state as any).products.fetching);

  if (fetchingProducts) {
    return <CircularLoadingIndicator />;
  }

  if (!Object.keys(product).length) {
    return (
      <EmptyState>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`Product Not Found | ${EVENT_NAME}`}</title>
          <meta name="description" content={`Product not found in ${EVENT_NAME} event.`} />
        </Helmet>
        Product not found
      </EmptyState>
    );
  }

  return (
    <ProductDetailTabsContextStateProvider>
      <RootContainer>
        <ProductHighlight product={product} />
        <ProductDescriptionContainer product={product} />
        <FooterWrapper>
          <Footer hashState={`/products/${product.id}`} />
        </FooterWrapper>
      </RootContainer>
    </ProductDetailTabsContextStateProvider>
  );
};

export default ProductDetailPage;
