const bannerUtils = {
  toImage: (src: string, href = null) => ({
    src,
    href,
    type: 'image',
  }),
};

export const en = {
  entry: {
    to_exhibition_hall: 'Enter Exhibition Hall',
    how_to_use_oms: '<b>How to utilize</b><b>virtual expo?</b>',
  },
  banners: {
    left: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/de949327-4e86-4fe3-946c-36cd4a4981ca.png',
      ),
    ],
    right: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/de949327-4e86-4fe3-946c-36cd4a4981ca.png',
      ),
    ],
    middle: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/de949327-4e86-4fe3-946c-36cd4a4981ca.png',
        'https://www.wsew-build.jp/en-gb/expo.html',
      ),
    ],
    mobile: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/de949327-4e86-4fe3-946c-36cd4a4981ca.png',
        'https://www.wsew-build.jp/en-gb/expo.html',
      ),
    ],
  },
};

export const ja = {
  entry: {
    to_exhibition_hall: '展示会場はこちら',
    how_to_use_oms: '<b>はじめに</b><i>オンライン来場・商談サービスの使い方</i>',
  },
  banners: {
    left: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/76dc9e0b-fc4b-40a5-855f-7bd9df233c68.png',
      ),
    ],
    right: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/76dc9e0b-fc4b-40a5-855f-7bd9df233c68.png',
      ),
    ],
    middle: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/76dc9e0b-fc4b-40a5-855f-7bd9df233c68.png',
        'https://www.wsew-build.jp/ja-jp/expo.html',
      ),
    ],
    mobile: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/76dc9e0b-fc4b-40a5-855f-7bd9df233c68.png',
        'https://www.wsew-build.jp/ja-jp/expo.html',
      ),
    ],
  },
};
