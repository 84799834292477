import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import smoothscroll from 'smoothscroll-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import Favicon from 'react-favicon';
import * as Sentry from '@sentry/browser';
import { StylesProvider } from '@material-ui/core/styles';

import 'i18n';
import ReduxObserver from 'components/ReduxObserver';
import { APP } from 'appenv';
import polyu618Favicon from 'assets/favicon-polyu618.ico';
import hkstop608Favicon from 'assets/favicon-hkstp608.ico';
import paloalto623Favicon from 'assets/favicon-paloalto623.ico';
import ms624Favicon from 'assets/favicon-ms624.ico';
import scmp711Favicon from 'assets/favicon-scmp711.ico';
import lighthouse710Favicon from 'assets/favicon-lighthouse710.ico';
import sph706Favicon from 'assets/favicon-sph706.ico';
import picox707Favicon from 'assets/favicon-picox707.ico';
import xtraFavicon from 'assets/favicon-eventxtra.ico';
import uzabase826Favicon from 'assets/favicon-uzabase826.ico';
import neoventure1012Favicon from 'assets/favicon-neoventure1012.ico';
import jetro817Favicon from 'assets/favicon-jetro817.ico';
import mig0828Favicon from 'assets/favicon-mig0828.jpg';
import reed902Favicon from 'assets/favicon-reed902.ico';
import reedMonoN909Favicon from 'assets/favicon/reedMonoN909.ico';
import reedCosme909Favicon from 'assets/favicon/reedCosme909.ico';
import reedUi909Favicon from 'assets/favicon/reedUi909Favicon.ico';
import reedSoumn916Favicon from 'assets/favicon/reedSoumn916.ico';
import reedEdix916Favicon from 'assets/favicon/reedEdix916.ico';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import './index.css';
import AppRouter from 'routes/AppRouter';
import { UserSessionContextStateProvider } from 'components/UserSession';
import { EventDatetimeContextStateProvider } from 'components/EventDatetime';
import rootReducer from 'models';
import { BookmarksContextStateProvider } from 'components/modals/bookmarks/Bookmarks';
import RongCloudChat from 'components/RongCloudChat';
import Apps from 'apps';

const stagingRedirectLookup = {
  'hkstp-globalmatching2020': true,
  'polyuspeed-vfair': true,
};
const hostParts = window.location.host.split('.');
if (stagingRedirectLookup[hostParts[0]] && hostParts.includes('2vanx')) {
  window.location.assign(window.location.href.replace('2vanx', 'eventxtra'));
}

smoothscroll.polyfill();

Sentry.init({ dsn: 'https://102260f2878947fe97af05b72446502d@o137190.ingest.sentry.io/5280956' });

const store = configureStore({
  reducer: rootReducer,
});

const faviconUrl = (() => {
  switch (APP) {
    case 'hkstp608':
      return hkstop608Favicon;
    case 'polyu618':
      return polyu618Favicon;
    case 'paloalto623':
      return paloalto623Favicon;
    case 'ms624':
      return ms624Favicon;
    case 'scmp711':
      return scmp711Favicon;
    case 'lighthouse710':
      return lighthouse710Favicon;
    case 'sph706':
    case Apps.Sph818:
      return sph706Favicon;
    case 'picox707':
      return picox707Favicon;
    case Apps.Neoventure1012:
      return neoventure1012Favicon;
    case Apps.Jetro817:
      return jetro817Favicon;
    case Apps.VirtualDemo:
    case Apps.VirtualPressCon:
      return xtraFavicon;
    case Apps.Uzabase826:
      return uzabase826Favicon;
    case Apps.Mig0828:
      return mig0828Favicon;
    case Apps.Reed902:
      return reed902Favicon;
    case Apps.ReedMonoN909:
      return reedMonoN909Favicon;
    case Apps.ReedCosme909:
      return reedCosme909Favicon;
    case Apps.ReedUi909:
      return reedUi909Favicon;
    case Apps.ReedSoumn916:
      return reedSoumn916Favicon;
    case Apps.ReedEdix916:
      return reedEdix916Favicon;
    default:
      return null;
  }
})();

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <>
      <Favicon url={faviconUrl} />
      <Provider store={store}>
        <ReduxObserver />
        <StylesProvider injectFirst>
          <EventDatetimeContextStateProvider>
            <BookmarksContextStateProvider>
              <UserSessionContextStateProvider>
                <RongCloudChat>
                  <AppRouter />
                </RongCloudChat>
              </UserSessionContextStateProvider>
            </BookmarksContextStateProvider>
          </EventDatetimeContextStateProvider>
        </StylesProvider>
      </Provider>
    </>,
    document.getElementById('root'),
  );
});
