import { request } from 'graphql-request';
import Apps from 'apps';
import { API_URL, EVENT_TOKEN, REED_APPS } from 'appenv';
import { envSwitch } from 'utils/envUtils';

export const CommonLocalizableString = `
  fragment CommonLocalizableString on LocalizableString {
    values(locales: $locales) {
      locale
      value
    }
  }
`;

export const CommonLocalizableImage = `
  fragment CommonLocalizableImage on LocalizableImage {
    values(locales: $locales) {
      locale
      value {
        originalImageUrl
      }
    }
  }
`;

export const CommonLocalizableVideo = `
  fragment CommonLocalizableVideo on LocalizableVideo {
    values(locales: $locales) {
      locale
      value {
        playbackUrl
      }
    }
  }
`;

export const CommonLocalizableFile = `
  fragment CommonLocalizableFile on LocalizableFile {
    values(locales: $locales) {
      locale
      value {
        fileUrl
      }
    }
  }
`;

export const NonQuestionContentElement = `
  fragment NonQuestionContentElement on BoothContentElement {
    __typename
    ... on BoothRichtextContentElement {
      contentRichtextHtml {
        ...CommonLocalizableString
      }
    }
    ... on BoothImageContentElement {
      image {
        ...CommonLocalizableImage
      }
    }
    ... on BoothVideoContentElement  {
      videoFile {
        ...CommonLocalizableVideo
      }
    }
    ... on BoothFileContentElement {
      file {
        ...CommonLocalizableFile
      }
    }
  }
`;

export const CommonBoothTier = `
  fragment CommonBoothTier on BoothTier {
    __typename
    name {
      ...CommonLocalizableString
    }
  }
`;

export const CommonBooth = `
  fragment CommonBooth on Booth {
    id
    appliedDesignCodename
    appointmentBookingUrl
    meetupEnabled
    website
    boothNumber
    description {
      ...CommonLocalizableString
    }
    name {
      ...CommonLocalizableString
    }
    logo {
      ...CommonLocalizableImage
    }
    tier {
      ...CommonBoothTier
    }
    categories {
      nodes {
        id
        name {
          ...CommonLocalizableString
        }
      }
    }
    designElements {
      nodes {
        id
        name
        kind
        color
        image {
          ...CommonLocalizableImage
        }
        video {
          ...CommonLocalizableVideo
        }
        contentRichtextHtml {
          ...CommonLocalizableString
        }
        thumbnailImage {
          ...CommonLocalizableImage
        }
        pdf {
          ...CommonLocalizableFile
        }
      }
    }
    rootContentElement {
      tabs {
        nodes {
          title {
            ...CommonLocalizableString
          }
          body {
            nodes {
              __typename
              id
              ...NonQuestionContentElement
              ... on BoothQuestionContentElement {
                question {
                  ...CommonLocalizableString
                }
                answer {
                  ...NonQuestionContentElement
                }
              }
            }
          }
        }
      }
    }
  }
`;

const eventByTokenQuery = `
  ${CommonLocalizableString}
  ${CommonLocalizableImage}
  ${CommonLocalizableVideo}
  ${CommonLocalizableFile}
  ${CommonBoothTier}
  ${NonQuestionContentElement}
  ${CommonBooth}

  query($eventToken: String!, $locales: [Locale!]!) {
    eventByToken(eventToken: $eventToken) {
      boothTiers {
        nodes {
          ...CommonBoothTier
        }
      }
      halls {
        nodes {
          id
          name {
            ...CommonLocalizableString
          }
          booths(first: 30) {
            nodes {
              ...CommonBooth
            }
          }
        }
      }
    }
  }
`;

const apiUrl = envSwitch([
  [[Apps.Jetro817, Apps.Mig0828, ...REED_APPS], 'https://api-virtual.eventxtra.com/graphql'],
], API_URL);

const eventToken = envSwitch([
  [Apps.Jetro817, 'gbtyes6dGZHmhdKTiu4RYxyw'],
  [Apps.Mig0828, 'KKMnYc8zU2UTkxrBGptpCWyk'],
  [Apps.Reed902, 'P5fqMS24CCsFk8sZ4HhdJQCV'],
  [Apps.ReedMonoN909, 'mCMz7BsfwgRiB8b88usCGQFZ'],
  [Apps.ReedCosme909, 'sjeTxYwcdp3xv6tDPhdkitT9'],
  [Apps.ReedUi909, 'UuLkUt9zW4TFagEQXLThA6oz'],
  [Apps.ReedSoumn916, 'MAdSPZaKXxrP9h86ukDxfWmd'],
  [Apps.ReedEdix916, '6Rx958Uc8DNH5vtqJYrZsW4H'],
], EVENT_TOKEN);

const eventByToken = (locales = ['en']): Promise<any> => request(
  apiUrl,
  eventByTokenQuery,
  {
    eventToken,
    locales,
  },
).then((result) => result.eventByToken);

export default eventByToken;
