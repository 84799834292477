import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Link, NavLink, matchPath } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAvailableLocales } from 'models/localization';
import { useTranslation } from 'react-i18next';
import Routes from 'Routes';
import BookmarksModal from 'components/modals/bookmarks/BookmarksModal';
import { UserSessionContext } from 'components/UserSession';
import { SearchFieldStateProvider } from 'components/SearchFieldContext';
import {
  ENABLED_FEATURES,
  PAGE_NAMES,
  BRAND_COLOR,
  APP,
  SUB_HALL,
  REED_APPS,
} from 'appenv';
import Apps from 'apps';
import { envSwitch } from 'utils/envUtils';
import SearchField from './desktop/SearchField';
import UserAccountView from './UserAccountView';
import HelpButton from './HelpButton';
import BookmarksButton from './BookmarksButton';
import { BookmarksContext } from '../modals/bookmarks/Bookmarks';
import AppBarMenuLink from './desktop/AppBarMenuLink';
import LocaleSelector from './LocaleSelector';

const RootContainer = styled.div`
  width: 100%;
  z-index: 1000;
  position: relative;
`;

const useAppBarStyles = makeStyles(() => ({
  root: {
    zIndex: 1000,
    maxHeight: '64px',
  },
  colorPrimary: {
    color: '#484848',
    backgroundColor: '#FFF',
  },
}));

const StyledToolbar = withStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}))(Toolbar);

const StyledSphLogoContainer = styled.div`
  flex: 1 0 auto;
  max-width: 236.54px;
`;

const StyledDefaultLogoContainer = styled.div`
  flex: 0 0 auto;
  width: 232px;
`;

const StyledLogoContainer = envSwitch([
  [Apps.SPH706, StyledSphLogoContainer],
], StyledDefaultLogoContainer);

const StyledLinksContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledActionsContainer = styled.div`
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  margin-left: ${({ hideUser }) => (hideUser ? 200 : 0)}px;
`;

const StyledLink = styled(Link)`
  display: inline-flex;
`;

const StyledNavLink = styled(NavLink)`
  flex: 0 1 auto;
  color: #484848;
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  padding: 6px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  word-break: keep-all;
  white-space: nowrap;
`;

const CustomButton = styled.div`
  flex: 0 1 auto;
  color: #484848;
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  padding: 6px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  word-break: keep-all;
  white-space: nowrap;
  cursor: pointer;
`;

const activeLinkStyle = {
  color: BRAND_COLOR || '#0CA58B',
};

const StyledLogo = styled.img`
  display: inline;
  align-self: center;
  height: ${({ logoHeight }) => logoHeight}px;
  vertical-align: middle;

  & :not(:last-child) {
    margin-right: 24px;
  }
`;

const TranslatedName = styled.div`
  margin-top: 5px;
`;

const nonEngRegex = new RegExp(/([A-Za-z0-9$&+,:;=?@#|'<>.^*()%!-\\ ]*)(.*)/);
const getTranslatedName = (name) => (envSwitch([
  ['sph706', () => {
    if (!name) return [name, null];
    const matched = name.match(nonEngRegex);
    return [matched[1].trim(), matched[2].trim()];
  }],
], () => [name, null])());

export default function DesktopAppBar({
  hideSearchBar,
  hideUser,
  logos,
  hideHelp,
  bookmarksModalOpen,
  openBookmarks,
  closeBookmarks,
}) {
  const appBarClasses = useAppBarStyles();
  const { t } = useTranslation();

  const { canSee, firstVisibleRoute, userSession } = useContext(UserSessionContext);

  const availableLocales = useSelector(selectAvailableLocales);

  const logoHeight = useMemo(() => {
    if (REED_APPS.includes(APP)) {
      return 40;
    }

    switch (APP) {
      case 'polyu618':
        return 48;
      case 'ms624':
        return 30;
      case 'scmp711':
        return 40;
      case 'hkstp608':
        return 56;
      case 'sph706':
      case Apps.Lighthouse710:
        return 50;
      case Apps.Neoventure1012:
        return 56;
      case Apps.Jetro817:
        return 45;
      default:
        return 40;
    }
  }, []);

  const handleClick = (key) => {
    window.trackingEvent('Event Navigation', 'MainMenu_Click', key);
  };

  const [lobbyName, lobbyTranslatedName] = useMemo(() => getTranslatedName(PAGE_NAMES.lobby || t('page.lobby', 'Lobby')), [t]);
  const [hallName, hallTranslatedName] = useMemo(() => getTranslatedName(PAGE_NAMES.hall || t('page.hall', 'Exhibition Hall')), [t]);
  const [webinarName, webinarTranslatedName] = useMemo(() => getTranslatedName(PAGE_NAMES.webinar || t('page.webinar', 'Webinars')), [t]);
  const [participantName, participantTranslatedName] = useMemo(() => getTranslatedName(t('page.participants', PAGE_NAMES.participant)), [t]);
  const [newsName, newsTranslatedName] = useMemo(() => getTranslatedName(PAGE_NAMES.news), []);
  const [customPageName] = useMemo(() => getTranslatedName(PAGE_NAMES.custom || t('page.custom', 'Custom Page')), [t]);

  const webinarLink = (() => {
    switch (APP) {
      case 'ms624': return (
        <StyledNavLink onClick={() => handleClick('Webinar')} to="/about" activeStyle={activeLinkStyle}>
          {PAGE_NAMES.webinar}
        </StyledNavLink>
      );
      case Apps.Lighthouse710: return (
        <StyledNavLink
          onClick={() => handleClick('Hall')}
          to={Routes.hall}
          activeStyle={activeLinkStyle}
          isActive={(match, location) => (
            matchPath(location.pathname, {
              path: [Routes.hall, Routes.booth()],
              exact: true,
            })
          )}
        >
          <div>{hallName}</div>
          {hallTranslatedName && <TranslatedName>{hallTranslatedName}</TranslatedName>}
        </StyledNavLink>
      );
      default: return (
        <StyledNavLink onClick={() => handleClick('Webinar')} to={Routes.webinar} activeStyle={activeLinkStyle}>
          <div>{webinarName}</div>
          {webinarTranslatedName && <TranslatedName>{webinarTranslatedName}</TranslatedName>}
        </StyledNavLink>
      );
    }
  })();

  const hallLink = (() => {
    switch (APP) {
      case 'sph706': {
        const subLinks = SUB_HALL.map((it) => ({
          name: it.name,
          to: it.link,
          onClick: () => handleClick('Hall'),
        }));
        return (
          <AppBarMenuLink
            name={hallName}
            translatedName={hallTranslatedName}
            activeStyle={activeLinkStyle}
            isActive={(location) => (
              matchPath(location.pathname, {
                path: [Routes.hall, Routes.booth()],
                exact: true,
              })
            )}
            subLinks={subLinks}
          />
        );
      }
      case Apps.Lighthouse710:
        return (
          <StyledNavLink onClick={() => handleClick('Webinar')} to={Routes.webinar} activeStyle={activeLinkStyle}>
            <div>{webinarName}</div>
            {webinarTranslatedName && <TranslatedName>{webinarTranslatedName}</TranslatedName>}
          </StyledNavLink>
        );
      default: return (
        <StyledNavLink
          onClick={() => handleClick('Hall')}
          to={Routes.hall}
          activeStyle={activeLinkStyle}
          isActive={(match, location) => (
            matchPath(location.pathname, {
              path: [Routes.hall, Routes.booth()],
              exact: true,
            })
          )}
        >
          <div>{hallName}</div>
          {hallTranslatedName && <TranslatedName>{hallTranslatedName}</TranslatedName>}
        </StyledNavLink>
      );
    }
  })();

  const newsLink = (() => {
    switch (APP) {
      case Apps.SPH706: return (
        <StyledNavLink to="/" target="_blank" rel="noopener noreferrer">
          <div>{newsName}</div>
          {newsTranslatedName && <TranslatedName>{newsTranslatedName}</TranslatedName>}
        </StyledNavLink>
      );
      default: return null;
    }
  })();

  const LogoLinkProps = envSwitch([
    [REED_APPS, {
      to: '//',
      component: React.forwardRef((props, ref) => (
        <a ref={ref} href={t('common:url.exhibition_website')} target="_blank" rel="noopener noreferrer">
          {props?.children}
        </a>
      )),
    }],
  ], { to: Routes[firstVisibleRoute] });

  const sortedLinks = [
    {
      element: (
        <StyledNavLink
          key="custom"
          to={Routes.custom}
          onClick={() => handleClick('Custom Page')}
          activeStyle={activeLinkStyle}
          isActive={(match, location) => (
            matchPath(location.pathname, {
              path: [Routes.custom],
              exact: true,
            })
          )}
        >
          <div>{customPageName}</div>
        </StyledNavLink>
      ),
      show: canSee('lobby') && ENABLED_FEATURES.customPage,
      sort: envSwitch([[REED_APPS, 1]], 0),
    },
    {
      element: (
        <StyledNavLink
          key="products"
          to={Routes.products}
          onClick={() => handleClick('Product Page')}
          activeStyle={activeLinkStyle}
          isActive={(match, location) => (
            matchPath(location.pathname, {
              path: [Routes.products, Routes.productDetail()],
              exact: true,
            })
          )}
        >
          <div>{PAGE_NAMES.products || t('page.products', 'Products')}</div>
        </StyledNavLink>
      ),
      show: canSee('products'),
      sort: envSwitch([[REED_APPS, 0]], 1),
    },
  ]
    .filter((it) => it.show)
    .sort((a, b) => a.sort - b.sort)
    .map((it) => it.element);

  return (
    <>
      <RootContainer>
        <AppBar
          position="static"
          className={`${appBarClasses.root} ${appBarClasses.colorPrimary}`}
          elevation={3}
        >
          <StyledToolbar>
            <StyledLogoContainer>
              <StyledLink {...LogoLinkProps}>
                {logos.map((logoUrl) => <StyledLogo logoHeight={logoHeight} src={logoUrl} alt="Event Logo" key={`image-${logoUrl}`} />)}
              </StyledLink>
            </StyledLogoContainer>
            <StyledLinksContainer>
              {
                canSee('lobby') && (
                  <StyledNavLink
                    onClick={() => handleClick('Lobby')}
                    to={Routes.lobby}
                    activeStyle={activeLinkStyle}
                    isActive={(match, location) => (
                      matchPath(location.pathname, {
                        path: [Routes.lobby, Routes.root],
                        exact: true,
                      })
                    )}
                  >
                    <div>{lobbyName}</div>
                    {lobbyTranslatedName && <TranslatedName>{lobbyTranslatedName}</TranslatedName>}
                  </StyledNavLink>
                )
              }
              {canSee('hall') && hallLink}
              {canSee('webinar') && webinarLink}
              {canSee('news') && newsLink}
              {
                canSee('participant') && (
                  <StyledNavLink onClick={() => handleClick('Participant')} to={Routes.participant} activeStyle={activeLinkStyle}>
                    <div>{participantName}</div>
                    {lobbyTranslatedName && <TranslatedName>{participantTranslatedName}</TranslatedName>}
                  </StyledNavLink>
                )
              }
              {
                Apps.Mig0828 === APP && (
                  <CustomButton onClick={() => handleClick('Networking Lounge')}>Networking Lounge</CustomButton>
                )
              }
              {sortedLinks}
            </StyledLinksContainer>
            <StyledActionsContainer hideUser={hideUser}>
              {canSee('lobby') && (
                <>
                  <BookmarksContext.Consumer>
                    {({ bookmarkedAttachments }) => (
                      (ENABLED_FEATURES.bookmarks && Object.keys(bookmarkedAttachments).length !== 0) && <BookmarksButton openBookmarks={openBookmarks} />
                    )}
                  </BookmarksContext.Consumer>
                  <BookmarksModal open={bookmarksModalOpen} onClose={closeBookmarks} />
                </>
              )}
              {
                !hideSearchBar && canSee('search') && (
                  <SearchFieldStateProvider>
                    <SearchField />
                  </SearchFieldStateProvider>
                )
              }
              {availableLocales.length > 1 && <LocaleSelector />}
              {!hideHelp && <HelpButton />}
              {!hideUser && userSession && <UserAccountView />}
            </StyledActionsContainer>
          </StyledToolbar>
        </AppBar>
      </RootContainer>
    </>
  );
}

DesktopAppBar.propTypes = {
  hideSearchBar: PropTypes.bool,
  hideUser: PropTypes.bool,
  logos: PropTypes.arrayOf(PropTypes.string).isRequired,
  hideHelp: PropTypes.bool,
  bookmarksModalOpen: PropTypes.bool.isRequired,
  openBookmarks: PropTypes.func.isRequired,
  closeBookmarks: PropTypes.func.isRequired,
};

DesktopAppBar.defaultProps = {
  hideSearchBar: false,
  hideUser: false,
  hideHelp: false,
};
