/* eslint-disable camelcase */
import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';
import { selectLocale } from 'models/localization';
import union from 'lodash/union';
import { fetchCMS, updateFromCMS, fetchBoothById } from './common/thunks';
import {
  LocalizableImage,
  LocalizableVideo,
  LocalizableString,
  LocalizableFile,
  WithTypename,
} from './common/types';

export enum DesignElementKind {
  Image = 'IMAGE',
  Richtext = 'RICHTEXT',
  Color = 'COLOR',
  Video = 'VIDEO',
  PDF = 'PDF',
}

export interface DesignElement {
  id: string;
  name: string;
  kind: DesignElementKind;
  color?: string;
  image?: LocalizableImage;
  video?: LocalizableVideo;
  contentRichtextHtml?: LocalizableString;
  thumbnailImage?: LocalizableImage;
  pdf?: LocalizableFile;
}

export type BoothContentElement = WithTypename<{
  id: string;
  contentRichtextHtml?: LocalizableString;
  image?: LocalizableImage;
  videoFile?: LocalizableVideo;
  file?: LocalizableFile;
  question?: LocalizableString;
  answer?: BoothContentElement;
}>;

export interface Booth {
  salesImg: any;
  id: string;
  appliedDesignCodename: string;
  appointmentBookingUrl: string;
  meetupEnabled: boolean;
  website: string;
  boothNumber: string;
  description: LocalizableString;
  name: LocalizableString;
  logo: LocalizableImage;
  tier: WithTypename<{ name: LocalizableString }>;
  designElements: DesignElement[];
  categories: { id: string; name: LocalizableString }[];
  rootContentElement: {
    tabs: {
      title: LocalizableString;
      body: BoothContentElement[];
    }[];
  };
  hasAttachment: boolean;
  attachmentTab: number;
}

const boothsAdapter = createEntityAdapter<Booth>();

export const {
  selectIds: selectBoothIds,
  selectEntities: selectBoothEntities,
  selectAll: selectAllBooths,
  selectById: selectBoothById,
} = boothsAdapter.getSelectors((state: any) => state.cms.booths);

export const selectBoothsByKeyword = (keyword: string, options: any = {}) => {
  const { locale } = options;
  const limit = options.limit || undefined;
  const lowerCaseKeyword = keyword.toLowerCase();
  return createSelector(
    [selectAllBooths, selectLocale],
    (booths, fallbackLocale) => (
      booths
        .filter((booth) => (
          lowerCaseKeyword && (
            (booth.name[locale] || booth.name[fallbackLocale])?.toLowerCase().includes(lowerCaseKeyword)
            || booth.categories.some((category) => (category.name[locale] || category.name[fallbackLocale])?.toLowerCase().includes(lowerCaseKeyword))
          )
        ))
        .slice(0, limit)
    ),
  );
};

// TODO: the below one only works for single-level filtering
export const filterBoothsByKeywords = (keyword: any) => {
  if (Object.keys(keyword).length === 0) return selectAllBooths;
  return createSelector(
    selectAllBooths,
    (booths) => {
      const options = Object.keys(keyword);
      return booths.filter((booth) => {
        const names = booth.categories
          .flatMap((category) => Object.values(category.name));
        return options.every((it) => names.includes(it));
      });
    },
  );
};

// TODO: the below one only works for single-level filtering
export const getFilterOptionByBooth = (fallbackLocale = 'en') => createSelector(
  [selectAllBooths, selectLocale],
  (booths, locale) => {
    const options: any = { count: {} };
    const categories = booths
      .flatMap((it) => it.categories.map((category) => category.name[locale] || category.name[fallbackLocale]))
      .filter((it) => it);

    union(categories).forEach((it) => {
      options[it] = [it];
      options.count[it] = 0;
    });

    categories.forEach((it) => {
      options.count[it] += 1;
    });

    return options;
  },
);

export const boothsSlice = createSlice({
  name: 'booths',
  initialState: boothsAdapter.getInitialState({ fetching: true }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCMS.pending, (state: any) => {
      state.fetching = true;
    });
    builder.addCase<any>(fetchCMS.fulfilled, (state, action) => {
      boothsAdapter.upsertMany(state, action.payload?.booths || {});
      state.fetching = false;
    });
    builder.addCase(fetchCMS.rejected, (state: any) => {
      state.fetching = false;
    });
    builder.addCase<any>(updateFromCMS.fulfilled, (state, action) => {
      boothsAdapter.upsertMany(state, action.payload?.booths || {});
    });
    builder.addCase<any>(fetchBoothById.fulfilled, (state, action) => {
      boothsAdapter.upsertMany(state, action.payload?.booths || {});
    });
  },
});

export default boothsSlice.reducer;
