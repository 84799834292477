import LogoEn1 from 'assets/main_lobby/top-logo-902-en-1.png';
import LogoEn2 from 'assets/main_lobby/top-logo-902-en-2.png';
import LogoJa1 from 'assets/main_lobby/top-logo-902-ja-1.png';
import LogoJa2 from 'assets/main_lobby/top-logo-902-ja-2.png';

export const en = {
  logos: [LogoEn2, LogoEn1],
  entry: {
    to_exhibition_hall: 'Enter Exhibition Hall',
    how_to_use_oms: '<b>How to utilize</b><b>virtual expo?</b>',
  },
  banners: {
    left: [
      { src: '/res/902/sub_logo_LSsm_en.png', type: 'image' },
    ],
    right: [
      { src: '/res/902/sub_logo_JMWsm_en.png', type: 'image' },
    ],
    middle: [
      { src: '/res/902/banner_en.png', type: 'image', href: 'https://www.lifestyle-expo.jp/ls_jmw_top_en/' },
    ],
    mobile: [
      { src: '/res/902/banner_en.png', type: 'image', href: 'https://www.lifestyle-expo.jp/ls_jmw_top_en/' },
      { src: '/res/902/sub_logo_LSsm_en.png', type: 'image' },
      { src: '/res/902/sub_logo_JMWsm_en.png', type: 'image' },
    ],
  },
};

export const ja = {
  logos: [LogoJa2, LogoJa1],
  entry: {
    to_exhibition_hall: '展示会場はこちら',
    how_to_use_oms: '<b>はじめに</b><i>オンライン来場・商談サービスの使い方</i>',
  },
  banners: {
    left: [
      { src: '/res/902/sub_logo_LSsm_jp.png', type: 'image' },
    ],
    right: [
      { src: '/res/902/sub_logo_JMWsm_jp.png', type: 'image' },
    ],
    middle: [
      { src: '/res/902/banner_jp.png', type: 'image', href: 'https://www.lifestyle-expo.jp/ls_jmw_top/' },
    ],
    mobile: [
      { src: '/res/902/banner_jp.png', type: 'image', href: 'https://www.lifestyle-expo.jp/ls_jmw_top/' },
      { src: '/res/902/sub_logo_LSsm_jp.png', type: 'image' },
      { src: '/res/902/sub_logo_JMWsm_jp.png', type: 'image' },
    ],
  },
};
