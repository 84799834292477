import { request } from 'graphql-request';
import { API_URL, REED_APPS } from 'appenv';
import Apps from 'apps';
import { envSwitch } from 'utils/envUtils';
import {
  CommonLocalizableString,
  CommonLocalizableImage,
  CommonLocalizableVideo,
  CommonLocalizableFile,
  CommonBoothTier,
  NonQuestionContentElement,
  CommonBooth,
} from './eventByToken';

const boothByIdQuery = `
  ${CommonLocalizableString}
  ${CommonLocalizableImage}
  ${CommonLocalizableVideo}
  ${CommonLocalizableFile}
  ${CommonBoothTier}
  ${NonQuestionContentElement}
  ${CommonBooth}

  query($boothId: ID!, $locales: [Locale!]!) {
    node(id: $boothId) {
      ... on Booth {
        ...CommonBooth
      }
    }
  }
`;

const apiUrl = envSwitch([
  [[Apps.Jetro817, ...REED_APPS], 'https://api-virtual.eventxtra.com/graphql'],
], API_URL);

const boothById = (boothId: string, locales = ['en']) => request(
  apiUrl,
  boothByIdQuery,
  {
    boothId,
    locales,
  },
).then((result) => result.node);

export default boothById;
