import { combineReducers } from 'redux';
import exhibitorsReducer from './exhibitors';
import webinarsReducer from './webinars';
import participantsReducer from './participants';
import contactsReducer from './contacts';
import messageReducer from './messages';
import eventReducer from './event';
import conversationReducer from './conversations';
import chatTargetReducer from './chatTarget';
import localizationReducer from './localization';
import chatTargetGroupMembersReducer from './chatTargetGroupMembers';
import loginTimeReducer from './loginTime';
import defaultBookmarksReducer from './defaultBookmarks';
import bannerReducer from './banners';
import searchResultsReducer from './searchResults';
import productsReducer from './products';
import landingAgendaReducer from './landingAgenda';
import rtcCallingInfoReducer from './rtcCallingInfo';
import rtcCallingStatusReducer from './rtcCallingStatus';
import cmsReducer from './xtra_cms';

export default combineReducers({
  exhibitors: exhibitorsReducer,
  webinars: webinarsReducer,
  participants: participantsReducer,
  contacts: contactsReducer,
  messages: messageReducer,
  event: eventReducer,
  conversations: conversationReducer,
  chatTarget: chatTargetReducer,
  localization: localizationReducer,
  chatTargetGroupMembers: chatTargetGroupMembersReducer,
  loginTime: loginTimeReducer,
  defaultBookmarks: defaultBookmarksReducer,
  banners: bannerReducer,
  products: productsReducer,
  searchResults: searchResultsReducer,
  landingAgenda: landingAgendaReducer,
  rtcCallingInfo: rtcCallingInfoReducer,
  rtcCallingStatus: rtcCallingStatusReducer,
  cms: cmsReducer,
});
