import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import ArrowFowardIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { Collapse } from 'react-collapse';
import { BRAND_COLOR, FILTER_LIST } from 'appenv';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import '../index.css';

const MobileHallFilterContainer = styled.div`
  width: 100%;
  background-color: #FFF;
  border-bottom: 0.5px solid #DBDEE2;
  position: relative;
  z-index: 2;

  @media only screen and (min-width: 960px) {
    display: none;
  }
`;

const MobileFilterWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

const MobileFilterOption = styled.div`
  padding: 12px 8px;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 14px;
  color: #999;

  &:hover {
    color: ${BRAND_COLOR || '#0CA58B'};
  }

  @media only screen and (max-width: 370px) {
    font-size: 11px;
  }
`;

const MobileFilterSelectContainer = styled.div`
  width: 100%;
  padding: 8px;
  transition: all 0.3s;
`;

const FilterListContainer = styled.div`
  width: 100%;
  max-height: 350px;
  overflow: auto;
`;

const MobileFilterSelect = styled.div`
  font-size: 15px;
  line-height: 18px;
`;

const ArrowContainer = styled.div<{ selected: boolean }>`
  transform: ${({ selected }) => (selected ? 'rotate(-90deg) translateX(-2px)' : 'rotate(90deg) translateX(2px)')};
  display: inline-block;
  transition: inherit;
  padding: 4px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  padding: 20px 16px;
  display: inline-flex;
  justify-content: space-between;
  margin-top: 16px;
`;


const FilterTitleContainer = styled.div<{ isSelected?: boolean }>`
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  transition: all 0.3s;
  color: ${({ isSelected }) => (isSelected ? BRAND_COLOR || '#0CA58B' : '#484848')};
`;

const FilterTitleText = styled.span`
  font-weight: bold;
  font-size: 15px;
  padding: 9px 0;
  display: inline-block;
  text-transform: none;
`;

const StyledCheckbox = withStyles(() => ({
  colorPrimary: {
    color: BRAND_COLOR || '#0CA58B',
  },
}))(Checkbox);

const ClearAllButton = withStyles(() => ({
  containedPrimary: {
    textTransform: 'capitalize',
    color: '#808080',
    backgroundColor: '#F0F1F2',
    marginRight: '16px',
    '&:hover': {
      color: '#808080',
      backgroundColor: '#F0F1F2',
    },
  },
}))(Button);

const ShowExhibitorsButton = withStyles(() => ({
  containedPrimary: {
    textTransform: 'capitalize',
    color: '#FFF',
    backgroundColor: BRAND_COLOR || '#0CA58B',
    flex: '1 0 auto',
    '&:hover': {
      color: '#FFF',
      backgroundColor: BRAND_COLOR || '#0CA58B',
    },
  },
}))(Button);

const BottomButtonContainer = ({ resetSelectedFilter, numOfExhibitors, onClose }: any) => (
  <ButtonContainer>
    <ClearAllButton
      variant="contained"
      color="primary"
      onClick={resetSelectedFilter}
    >
      Clear All
    </ClearAllButton>
    <ShowExhibitorsButton
      variant="contained"
      color="primary"
      onClick={onClose}
    >
      {`Show ${numOfExhibitors} Exhibitors`}
    </ShowExhibitorsButton>
  </ButtonContainer>
);

const GreyBackground = styled.div`
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const FilterOption = ({
  filterCustomLabel,
  label,
  selectedFilter,
  filter,
  setSelectedFilter,
}: any) => {
  const onFilterTitleContainerClick = () => {
    const cloned = { ...selectedFilter };
    if (cloned?.[filter]?.length > 0) {
      delete cloned[filter];
    } else {
      cloned[filter] = [0];
    }
    setSelectedFilter(cloned);
  };


  const isSelected = selectedFilter?.[filter]?.length > 0;
  return (
    <FilterTitleContainer isSelected={isSelected} onClick={onFilterTitleContainerClick}>
      <StyledCheckbox color="primary" checked={isSelected} />
      <FilterTitleText>
        {filterCustomLabel[label] ? filterCustomLabel[label] : label}
      </FilterTitleText>
    </FilterTitleContainer>
  );
};

const MobileHallFilter = ({
  filterOptions,
  setSelectedFilter,
  resetSelectedFilter,
  filterCustomLabel,
  selectedFilter,
  numOfExhibitors,
}: any) => {
  const { t } = useTranslation();
  const [openTab, setOpenTab] = useState(false);
  const isDesktop = useMediaQuery('(min-width: 960px)', { defaultMatches: true });

  const filterOrdering = useMemo(() => {
    if (FILTER_LIST.length > 0) {
      return FILTER_LIST;
    }
    return Object.keys(filterOptions).filter((filterOption) => filterOption !== 'count');
  }, [filterOptions]);

  if (isDesktop) return null;

  return (
    <>
      {openTab && <GreyBackground />}
      <MobileHallFilterContainer>
        <MobileFilterWrapper>
          <MobileFilterOption onClick={() => setOpenTab((prev) => !prev)}>
            Category
            {
              Object.keys(selectedFilter).length > 0
              && ` (${t('filter.total_filtered_results', 'Filtering results: %{total}', { total: numOfExhibitors })})`
            }
            <ArrowContainer selected={openTab}>
              <ArrowFowardIcon style={{ fontSize: '12px' }} />
            </ArrowContainer>
          </MobileFilterOption>
        </MobileFilterWrapper>
        <Collapse isOpened={openTab}>
          <MobileFilterSelectContainer>
            <FilterListContainer>
              {filterOrdering?.map((filter, index) => (
                <MobileFilterSelect key={index}>
                  <FilterOption
                    label={filter}
                    filterCustomLabel={filterCustomLabel}
                    filter={filter}
                    selectedFilter={selectedFilter}
                    setSelectedFilter={setSelectedFilter}
                  />
                </MobileFilterSelect>
              ))}
            </FilterListContainer>
            <BottomButtonContainer
              numOfExhibitors={numOfExhibitors}
              resetSelectedFilter={resetSelectedFilter}
              onClose={() => setOpenTab(false)}
            />
          </MobileFilterSelectContainer>
        </Collapse>
      </MobileHallFilterContainer>
    </>
  );
};

export default MobileHallFilter;
